import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { has, isNil } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import * as fromGeneralInfoAction from '../../store/actions/app-config/general-info';
import * as fromUserProfileAction from '../../store/actions/app-settings/user-profile';
import * as fromAppConfigGeneralInfoState from '../../store/selectors/app-config';

import { PfHelperService } from '../pf-helper-service.service';

import { PropertyForceCustomErrorHandler } from './pf-interceptor.constants';

class ErrorWithData extends Error {
  data: {
    html: string;
    type: string;
    version: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class PfCustomErrorsHandlerInterceptor implements HttpInterceptor {
  private readonly customValidations;

  constructor(
    private store: Store<fromAppConfigGeneralInfoState.State>,
    private pfHelperService: PfHelperService
  ) {
    this.customValidations = {
      GetFarmCount: (resp) => {
        let error = false;
        let errorType: string;
        if (resp.Status.includes('OK')) {
          if (has(resp, 'Count') && resp.Count === 0) {
            error = true;
            errorType = 'no records';
          }
          if (has(resp, 'Count') && resp.Count >= 1000) {
            error = true;
            errorType = 'max exceeded';
          }
        }

        return error ? errorType : null;
      }
    };
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (request.headers.has(PropertyForceCustomErrorHandler)) {
      const header = request.headers.get(PropertyForceCustomErrorHandler);
      const headers = request.headers.delete(PropertyForceCustomErrorHandler);
      const cleanedHeaderRequest = request.clone({ headers });

      return next.handle(cleanedHeaderRequest).pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        switchMap((event: any) => {
          if (event instanceof HttpResponse) {
            const customError = this.getCustomError(this.pfHelperService.getEndPoint(request.url), event.body.d);
            if (customError) {
              throw new Error(customError);
            }

            if (has(event, 'body.d') && !isNil(event.body.d)) {
              if (has(event.body.d, 'StatusCode') && event.body.d.StatusCode.includes('TERMS')) {
                const version = Number(event.body.d.AgreementVersion);
                const html = event.body.d.AgreementHtml;
                const lengthOfOpeningTag = 6;
                const startOfBody = html.indexOf('<body>');
                const endOfBody = html.indexOf('</body>');
                const parsedHtml = html.substring(startOfBody + lengthOfOpeningTag, endOfBody);
                const error = new ErrorWithData('ACCEPT_TERMS');
                error.data = {
                  html: parsedHtml,
                  type: header,
                  version
                };
                throw error;
              }

              if (has(event.body.d, 'StatusCode') && event.body.d.StatusCode.includes('EXPIRED')) {
                this.store.dispatch(new fromUserProfileAction.ChangeExpiredPassword(true));
              }

              if (
                has(event.body.d, 'Status') &&
                !(
                  event.body.d.Status.includes('OK') ||
                  event.body.d.Status.includes('NM') ||
                  event.body.d.Status.includes('MM')
                )
              ) {
                const status = event.body?.d?.Status ?? '';
                if (
                  !status.match('\\bcode13_IK\\b') &&
                  ['Invalid Access Key', 'Invalid accessKey', 'Invalid Key'].indexOf(status) === -1
                ) {
                  throw new Error(event.body.d.Status);
                }
              }
            }
          }

          return of(event);
        }),
        catchError(async (error) => {
          if (error instanceof HttpErrorResponse) {
            await this.pfHelperService.showSystemError('Something went wrong. Please try again later.');
          } else if (error.message === 'ACCEPT_TERMS') {
            if (error.data.type === 'PP-KEY-TERMS') {
              this.store.dispatch(
                new fromGeneralInfoAction.AcceptNewPropertyKeyTerms({
                  html: error.data.html,
                  version: error.data.version
                })
              );
            } else if (error.data.type === 'FARM-KEY-TERMS') {
              this.store.dispatch(
                new fromGeneralInfoAction.AcceptNewFarmKeyTerms({
                  html: error.data.html,
                  version: error.data.version
                })
              );
            }
          }

          throw error;
        })
      );
    }

    return next.handle(request);
  }

  private getCustomError(endPoint: string, response) {
    if (!has(this.customValidations, endPoint)) {
      return null;
    }

    return this.customValidations[endPoint](response);
  }
}
