import { AppSettingsSalesInfo } from '../../../typings/app';

import * as salesInfoActions from '../../actions/app-settings/sale-info';
import * as userProfileActions from '../../actions/app-settings/user-profile';

export interface State {
  error: Error;
  pending: boolean;
  salesInfo: AppSettingsSalesInfo | null;
  succeeded: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
  salesInfo: null,
  succeeded: false
};

export function reducer(
  state = initialState,
  action: salesInfoActions.SalesInfoActions | userProfileActions.UserProfileAction
) {
  switch (action.type) {
    case salesInfoActions.SalesInfoActionTypes.GetSalesInformation: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }
    case salesInfoActions.SalesInfoActionTypes.GetSalesInformationSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        salesInfo: action.payload,
        succeeded: true
      };
    }

    case salesInfoActions.SalesInfoActionTypes.ResetSalesInformation: {
      return initialState;
    }

    case userProfileActions.UserProfileActionTypes.UpdateSalesInfoFromInternalStorage: {
      return {
        ...state,
        error: null,
        pending: false,
        salesInfo: action.payload.salesInfo,
        succeeded: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getSalesInfo = (state: State) => state.salesInfo;
