import { Injectable } from '@angular/core';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from as fromPromise, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ApiProvider } from '../../providers/api.service';
import { PfHelperService } from '../../providers/pf-helper-service.service';
import { DeviceService } from '../../common/services/device/device.service';
import { LoggerService } from '../../common/services/logger/logger.service';
import { SecureStorageService } from '../../common/services/secure-storage/secure-storage.service';

import * as generalInfoAction from '../actions/app-config/general-info';
import * as generalInfoActions from '../actions/app-config/general-info';
import * as appConfigMapDataAction from '../actions/app-config/map-data';
import * as fromAppConfigMapDataActions from '../actions/app-config/map-data';
import * as fromAppConfig from '../selectors/app-config';
import * as fromAppSettings from '../selectors/app-settings';

@Injectable()
export class AppConfigEffects {
  resetKeys$ = createEffect(
    () =>
      this.actions$
        .pipe(ofType<generalInfoAction.ResetKeys>(generalInfoAction.GeneralConfigActionTypes.ResetKeys))
        .pipe(
          concatMap((action) => {
            return this.secureStorageService.updateInternalStorage(action.payload);
          })
        ),
    { dispatch: false }
  );

  getPropertyProfileKey$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<generalInfoAction.GetPropertyProfileKey>(
          generalInfoAction.GeneralConfigActionTypes.GetPropertyProfileKey
        )
      )
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getGeneralInfoAuthData),
          this.store.select(fromAppConfig.getGeneralInfoDeviceData)
        ),
        switchMap(([action, authData, deviceData]) => {
          return this.apiService
            .getKey(
              'GetKeyWithTACCheck',
              {
                deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
                office: authData.repCode,
                password: authData.password,
                username: authData.username
              },
              'PP-KEY-TERMS'
            )
            .pipe(
              concatMap(async (result) => {
                const res = {
                  key: result['d'].Key,
                  lastUpdatedTime: new Date().getTime()
                };
                if (action.payload.withKeyRefresh) {
                  this.store.dispatch({
                    payload: res.key,
                    type: 'KEY_REFRESHED'
                  });
                  await this.secureStorageService.updateInternalStorage({ propertyProfile: res });
                }

                return new generalInfoAction.GetPropertyProfileKeySuccess(res);
              }),
              catchError(async (error) => {
                await this.pfHelperService.showSystemError(error.message);
                this.store.dispatch({
                  payload: '',
                  type: 'KEY_REFRESHED'
                });
                return new generalInfoAction.GetPropertyProfileKeyFail(error);
              })
            );
        })
      )
  );

  getPropertyFarmKey$ = createEffect(() =>
    this.actions$
      .pipe(ofType<generalInfoAction.GetPropertyFarmKey>(generalInfoAction.GeneralConfigActionTypes.GetPropertyFarmKey))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getGeneralInfoAuthData),
          this.store.select(fromAppConfig.getGeneralInfoDeviceData)
        ),
        switchMap(([action, authData, deviceData]) =>
          this.apiService
            .getKey(
              'GetFarmKeyWithTACCheck',
              {
                deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
                office: authData.repCode,
                password: authData.password,
                username: authData.username
              },
              'FARM-KEY-TERMS'
            )
            .pipe(
              concatMap(async (result) => {
                const res = {
                  key: result['d'].Key,
                  lastUpdatedTime: new Date().getTime()
                };

                if (action.payload.withKeyRefresh) {
                  this.store.dispatch({
                    payload: res.key,
                    type: 'KEY_REFRESHED'
                  });
                  await this.secureStorageService.updateInternalStorage({ propertyFarm: res });
                }

                return new generalInfoAction.GetPropertyFarmKeySuccess(res);
              }),
              catchError(async (error) => {
                await this.pfHelperService.showSystemError(error.message);
                this.store.dispatch({
                  payload: '',
                  type: 'KEY_REFRESHED'
                });
                return new generalInfoAction.GetPropertyFarmKeyFail(error);
              })
            )
        )
      )
  );

  userAcceptedTermsForPropertyData$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<generalInfoAction.GetUserAcceptedTermsForPropertyData>(
          generalInfoAction.GeneralConfigActionTypes.GetUserAcceptedTermsForPropertyData
        )
      )
      .pipe(
        map((action: generalInfoAction.GetUserAcceptedTermsForPropertyData) => action.payload),
        withLatestFrom(
          this.store.select(fromAppConfig.getGeneralInfoAuthData),
          this.store.select(fromAppConfig.getGeneralInfoDeviceData)
        ),
        switchMap(([propertyData, authData, deviceData]) =>
          this.apiService
            .getData('userAcceptedTACPropertyData', {
              deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
              office: authData.repCode,
              password: authData.password,
              tcid: propertyData.tcid,
              username: authData.username
            })
            .pipe(
              switchMap((result) => {
                const key = {
                  key: result['d'].Key,
                  lastUpdatedTime: new Date().getTime()
                };
                this.secureStorageService.updateInternalStorage({ propertyProfile: key });
                return [
                  new generalInfoAction.GetUserAcceptedTermsForPropertyDataSuccess(key),
                  new generalInfoAction.GetPropertyProfileKeySuccess(key),
                  this.pfHelperService.actionAfterTermsAccepted
                ];
              }),
              catchError((error) => of(new generalInfoAction.GetUserAcceptedTermsForPropertyDataFail(error)))
            )
        )
      )
  );

  userAcceptedTermsForFarmData$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<generalInfoAction.GetUserAcceptedTermsForFarmData>(
          generalInfoAction.GeneralConfigActionTypes.GetUserAcceptedTermsForFarmData
        )
      )
      .pipe(
        map((action: generalInfoAction.GetUserAcceptedTermsForFarmData) => action.payload),
        withLatestFrom(
          this.store.select(fromAppConfig.getGeneralInfoAuthData),
          this.store.select(fromAppConfig.getGeneralInfoDeviceData)
        ),
        switchMap(([farmData, authData, deviceData]) =>
          this.apiService
            .getData('userAcceptedTACFarmData', {
              deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
              office: authData.repCode,
              password: authData.password,
              tcid: farmData.tcid,
              username: authData.username
            })
            .pipe(
              switchMap((result) => {
                const key = {
                  key: result['d'].Key,
                  lastUpdatedTime: new Date().getTime()
                };
                this.secureStorageService.updateInternalStorage({ propertyFarm: key });
                return [
                  new generalInfoAction.GetUserAcceptedTermsForFarmDataSuccess(key),
                  new generalInfoAction.GetPropertyFarmKeySuccess(key),
                  this.pfHelperService.actionAfterTermsAccepted
                ];
              }),
              catchError((error) => of(new generalInfoAction.GetUserAcceptedTermsForFarmDataFail(error)))
            )
        )
      )
  );

  getDeviceData$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<generalInfoAction.GetDeviceData>(generalInfoAction.GeneralConfigActionTypes.GetDeviceData),
        withLatestFrom(this.deviceService.getInfo())
      )
      .pipe(
        switchMap(([_action, deviceData]) => [new generalInfoAction.GetDeviceDataSuccess(deviceData)]),
        catchError((error) => of(new generalInfoAction.GetDeviceDataFail(error)))
      )
  );

  getLocationAfterLogout$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<fromAppConfigMapDataActions.GetInitialLocation>(
          appConfigMapDataAction.MapDataActionTypes.GetInitialLocation
        )
      )
      .pipe(
        switchMap(() =>
          fromPromise(this.geolocation.getCurrentPosition()).pipe(
            map((position) => {
              return new fromAppConfigMapDataActions.GetInitialLocationSuccess({
                lat: position.coords.latitude,
                lng: position.coords.longitude
              });
            }),
            catchError((error) => of(new fromAppConfigMapDataActions.GetInitialLocationFail(error)))
          )
        )
      )
  );

  onDataUpdate$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<generalInfoAction.UpdatePassword | generalInfoAction.UpdateCurrentAppIcon>(
          generalInfoAction.GeneralConfigActionTypes.UpdatePassword,
          generalInfoAction.GeneralConfigActionTypes.UpdateCurrentAppIcon
        )
      )
      .pipe(map(() => new generalInfoAction.SaveDataInInternalStorage()))
  );

  validateUpdateAuthData = createEffect(() =>
    this.actions$
      .pipe(
        ofType<generalInfoAction.ValidateUpdateAuthData>(
          generalInfoAction.GeneralConfigActionTypes.ValidateUpdateAuthData
        )
      )
      .pipe(
        switchMap((action) => [
          new generalInfoAction.SaveDataInInternalStorage(action.payload),
          new generalInfoAction.ValidateUpdateAuthDataSuccess()
        ])
      )
  );

  validateUpdateAuthDataSuccess = createEffect(
    () =>
      this.actions$
        .pipe(
          ofType<generalInfoAction.ValidateUpdateAuthDataSuccess>(
            generalInfoAction.GeneralConfigActionTypes.ValidateUpdateAuthDataSuccess
          )
        )
        .pipe(
          map(() => {
            this.store.dispatch(
              new generalInfoActions.ResetKeys({
                propertyFarm: {
                  key: 'BOGUS_FARM_KEY',
                  lastUpdatedTime: 0
                },
                propertyProfile: {
                  key: 'BOGUS_PROPERTY_KEY',
                  lastUpdatedTime: 0
                }
              })
            );
          })
        ),
    { dispatch: false }
  );

  handleInternalStorageData$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<generalInfoAction.SaveDataInInternalStorage>(
          generalInfoAction.GeneralConfigActionTypes.SaveDataInInternalStorage
        )
      )
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getInternalStorageGeneralInfoData),
          this.store.select(fromAppSettings.getInternalStorageAppSettingsData)
        ),
        switchMap(([action, generalInfoData, appSettingsData]) => {
          const dispatchActions: Action[] = [];

          if (generalInfoData.isAuthenticated) {
            const repCode = action.payload?.repCode;

            if (repCode) {
              dispatchActions.push(
                new generalInfoAction.GetAppSettingsData({
                  newAuthData: {
                    password: action.payload?.password,
                    repCode,
                    username: action.payload?.username
                  },
                  refreshUser: true
                })
              );
            } else {
              this.loggerService.updateAuthData(generalInfoData.authData, appSettingsData.user);

              this.secureStorageService.updateInternalStorage({
                ...generalInfoData,
                ...appSettingsData
              });
            }
          }

          return dispatchActions;
        })
      )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiProvider,
    private deviceService: DeviceService,
    private geolocation: Geolocation,
    private loggerService: LoggerService,
    private pfHelperService: PfHelperService,
    private secureStorageService: SecureStorageService,
    private store: Store<fromAppConfig.State>
  ) {}
}
