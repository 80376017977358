import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { camelCase, isNil, omit, pick } from 'lodash-es';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  REP_DATA_RESPONSE_PROVIDER_ID_DATATRACE,
  REP_DATA_RESPONSE_PROVIDER_ID_DATATRACE_DTAPI
} from '../../../constants/main.constants';
import { ADMIN_PORTAL_CONFIG, NETCALCS_REPORTS_CONFIG } from '../../../environments/environment';
import { adminPortalEndpoints } from '../../constants/admin-portal';
import { netCalcsEndpoints, netCalcsReportsDependencies } from '../../constants/netcalcs-reports';
import { MonetizationIapPhoneAndEmailCsvError } from '../../typings/monetization';
import { OwnerStatus } from '../../typings/walking-farm';

import { ApiProvider } from '../../providers/api.service';
import { PfHelperService } from '../../providers/pf-helper-service.service';
import { ApiService } from '../../common/services/api/api.service';

import * as propertyProfileActions from '../actions/property-data/property-profile';
import * as walkingFarmReportDetailsActions from '../actions/walking-farm/farm-report-details';
import * as fromAppConfig from '../selectors/app-config/index';
import * as fromAppSettings from '../selectors/app-settings/index';
import * as fromCalculators from '../selectors/calculators/index';
import * as fromPropertyData from '../selectors/property-data/index';
import * as fromReportFilters from '../selectors/walking-farm';
import * as fromWalkingFarm from '../selectors/walking-farm/index';

@Injectable()
export class ReportsEffects {
  sendTransactionDocument$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<propertyProfileActions.SendDocumentImage>(
          propertyProfileActions.PropertyProfileActionTypes.SendDocumentImage
        )
      )
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getPropertyKey),
          this.store.select(fromAppConfig.getRepDataProviderId),
          this.store.select(fromAppConfig.getGeneralInfoDeviceData),
          this.store.select(fromPropertyData.getSendDocumentApiData)
        ),
        switchMap(([action, propertyKey, providerId, deviceData, documentData]) => {
          const parameters = pick(action.payload, ['cmtIdentifier', 'recDate']);
          let dataToSend;

          let recDate: Date;
          let formattedRecDate: string;
          let recDateMonth: number;
          let recDateDate: number;

          switch (providerId) {
            case REP_DATA_RESPONSE_PROVIDER_ID_DATATRACE:
              dataToSend = {
                ...omit(action.payload, ['recDate', 'cmtIdentifier']),
                deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
                key: propertyKey,
                parameters: JSON.stringify({
                  ...pick(documentData, ['city', 'streetAddress', 'stateCode']),
                  cmtIdentifier: parameters.cmtIdentifier
                })
              };
              break;

            case REP_DATA_RESPONSE_PROVIDER_ID_DATATRACE_DTAPI:
              dataToSend = {
                ...omit(action.payload, ['recDate', 'cmtIdentifier']),
                deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
                key: propertyKey,
                parameters: JSON.stringify({
                  ...pick(documentData, ['city', 'streetAddress', 'stateCode', 'fips']),
                  cmtIdentifier: parameters.cmtIdentifier
                })
              };
              break;

            default:
              recDate = new Date(parameters.recDate);
              formattedRecDate = recDate.getFullYear() + '/';
              recDateMonth = recDate.getMonth() + 1;
              recDateDate = recDate.getDate();
              formattedRecDate += (recDateMonth > 9 ? '' : '0') + recDateMonth + '/';
              formattedRecDate += (recDateDate > 9 ? '' : '0') + recDateDate;
              dataToSend = {
                ...omit(action.payload, ['recDate', 'cmtIdentifier']),
                deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
                key: propertyKey,
                parameters: JSON.stringify({
                  ...pick(documentData, ['fips', 'apn']),
                  recDate: formattedRecDate
                })
              };
              break;
          }

          return this.apiProvider.getData('SendDocumentImage', dataToSend, 'PP-KEY-REFRESH', providerId).pipe(
            map(() => new propertyProfileActions.SendDocumentImageSuccess()),
            catchError((error) => of(new propertyProfileActions.SendDocumentImageFail(error)))
          );
        })
      )
  );

  exportPropertyProfile = createEffect(() =>
    this.actions$
      .pipe(
        ofType<propertyProfileActions.PropertyProfileExport>(
          propertyProfileActions.PropertyProfileActionTypes.PropertyProfileExport
        )
      )
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getGeneralInfoAuthData),
          this.store.select(fromPropertyData.getPropertyProfileHistoryState)
        ),
        map(([_action, authInfo, propertyProfileHistory]) => ({
          repCode: authInfo.repCode,
          reportId: propertyProfileHistory.selectedId
        })),
        switchMap((propertyProfileExport) => {
          if (propertyProfileExport.reportId && propertyProfileExport.repCode) {
            return this.apiService
              .sendRequest(
                {
                  method: 'POST',
                  requestOptions: {
                    body: propertyProfileExport,
                    responseType: 'blob'
                  },
                  url: `${NETCALCS_REPORTS_CONFIG.apiUrl}/v3/${netCalcsEndpoints.report}/PropertyReport`
                },
                netCalcsReportsDependencies.apiName
              )
              .pipe(
                map((reportPDFBlob) => new propertyProfileActions.PropertyProfileExportSuccess(reportPDFBlob)),
                catchError((error) => of(new propertyProfileActions.PropertyProfileExportFail(error)))
              );
          }

          return EMPTY;
        })
      )
  );

  exportPropertyProfileFail = createEffect(
    () =>
      this.actions$.pipe(
        ofType<propertyProfileActions.PropertyProfileExportFail>(
          propertyProfileActions.PropertyProfileActionTypes.PropertyProfileExportFail
        ),
        map(async () => {
          await this.apiService.displayAlert(
            netCalcsReportsDependencies.error.reportProperty.message,
            netCalcsReportsDependencies.error.reportProperty.title
          );
        })
      ),
    { dispatch: false }
  );

  exportPdfInPages = createEffect(() =>
    this.actions$
      .pipe(
        ofType<walkingFarmReportDetailsActions.ExportPdfInPages>(
          walkingFarmReportDetailsActions.FarmReportDetailsActionTypes.ExportPdfInPages
        )
      )
      .pipe(
        withLatestFrom(
          this.store.select(fromAppSettings.getAppSettingsUserProfileData),
          this.store.select(fromAppConfig.getGeneralInfoAuthData),
          this.store.select(fromReportFilters.getReportFiltersSortBy),
          this.store.select(fromReportFilters.getReportFiltersOwnerStatus),
          this.store.select(fromReportFilters.getReportFiltersLeadStatusSelected)
        ),
        filter(([_action, user, authData]) => !isNil(user) && !isNil(authData.repCode)),
        switchMap(([action, user, authData, sort, ownerStatus, leadStatus]) =>
          this.apiService
            .sendRequest(
              {
                method: 'POST',
                requestOptions: {
                  body: {
                    ...action.payload.exportData,
                    email: user.email,
                    repCode: authData.repCode,
                    ...this.handleFilterParameters(sort, ownerStatus, leadStatus)
                  },
                  responseType: 'blob'
                },
                url: `${NETCALCS_REPORTS_CONFIG.apiUrl}/v3/${netCalcsEndpoints.report}/Mail/WalkingFarm`
              },
              netCalcsReportsDependencies.apiName
            )
            .pipe(
              switchMap((reportPDFBlob) =>
                of(new walkingFarmReportDetailsActions.ExportPdfInPagesSuccess(reportPDFBlob))
              ),
              catchError((error) => of(new walkingFarmReportDetailsActions.ExportPdfInPagesFail(error)))
            )
        )
      )
  );

  exportPdfInPagesFail = createEffect(
    () =>
      this.actions$.pipe(
        ofType<walkingFarmReportDetailsActions.ExportPdfInPagesFail>(
          walkingFarmReportDetailsActions.FarmReportDetailsActionTypes.ExportPdfInPagesFail
        ),
        map(async () => {
          await this.apiService.displayAlert(
            netCalcsReportsDependencies.error.reportWalkingFarm.message,
            netCalcsReportsDependencies.error.reportWalkingFarm.title
          );
        })
      ),
    { dispatch: false }
  );

  exportPdfInPagesSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType<walkingFarmReportDetailsActions.ExportPdfInPagesSuccess>(
          walkingFarmReportDetailsActions.FarmReportDetailsActionTypes.ExportPdfInPagesSuccess
        ),
        map(async () => {
          await this.apiService.displayAlert(
            netCalcsReportsDependencies.notification.reportWalkingFarm.message,
            netCalcsReportsDependencies.notification.reportWalkingFarm.title
          );
        })
      ),
    { dispatch: false }
  );

  exportPdfInAveryLabels$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<walkingFarmReportDetailsActions.ExportPdfInAveryLabels>(
          walkingFarmReportDetailsActions.FarmReportDetailsActionTypes.ExportPdfInAveryLabels
        )
      )
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getGeneralInfoAuthData),
          this.store.select(fromReportFilters.getReportFiltersSortBy),
          this.store.select(fromReportFilters.getReportFiltersOwnerStatus),
          this.store.select(fromReportFilters.getReportFiltersLeadStatusSelected)
        ),
        switchMap(([action, authData, sortBy, filterOwnerStatus, filterLeadStatus]) =>
          this.apiService
            .sendRequest(
              {
                method: 'POST',
                requestOptions: {
                  body: {
                    repCode: authData.repCode,
                    reportId: action.payload,
                    ...this.handleFilterParameters(sortBy, filterOwnerStatus, filterLeadStatus)
                  },
                  responseType: 'blob'
                },
                url: `${NETCALCS_REPORTS_CONFIG.apiUrl}/v3/${netCalcsEndpoints.report}/WalkingFarm/Avery5160Labels`
              },
              netCalcsReportsDependencies.apiName
            )
            .pipe(
              map((reportPDFBlob) => new walkingFarmReportDetailsActions.ExportPdfInAveryLabelsSuccess(reportPDFBlob)),
              catchError((error) => {
                this.pfHelperService.showSystemError(error.message);
                return of(new walkingFarmReportDetailsActions.ExportPdfInAveryLabelsFail(error));
              })
            )
        )
      )
  );

  exportIapPhoneAndEmailCSV$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<walkingFarmReportDetailsActions.ExportIapPhoneAndEmailCSV>(
          walkingFarmReportDetailsActions.FarmReportDetailsActionTypes.ExportIapPhoneAndEmailCSV
        )
      )
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
        map(([action, parseUrlType]) => ({
          farmId: action.payload.farmId,
          parseUrlType
        })),
        switchMap(({ farmId, parseUrlType }) =>
          this.apiService
            .sendRequest({
              method: 'POST',
              requestOptions: {
                params: {
                  parseType: parseUrlType
                },
                responseType: 'text'
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.reports}/Farms/${farmId}/ContactDetails/Csv`
            })
            .pipe(
              map(
                (csvResult) =>
                  new walkingFarmReportDetailsActions.ExportIapPhoneAndEmailCSVSuccess({
                    csvResult
                  })
              ),
              catchError((error) => {
                let errorObject: MonetizationIapPhoneAndEmailCsvError = {
                  code: '',
                  description: 'There was an error downloading the CSV you requested.',
                  status: 0
                };

                try {
                  errorObject = JSON.parse(error.error);
                } catch (e) {
                  /* empty */
                }

                return of(new walkingFarmReportDetailsActions.ExportIapPhoneAndEmailCSVFailure({ error: errorObject }));
              })
            )
        )
      )
  );

  exportInCSV$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<walkingFarmReportDetailsActions.ExportCSV>(
          walkingFarmReportDetailsActions.FarmReportDetailsActionTypes.ExportCSV
        )
      )
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getAppInfoParseUrlType),
          this.store.select(fromReportFilters.getReportFiltersSortBy),
          this.store.select(fromReportFilters.getReportFiltersOwnerStatus),
          this.store.select(fromReportFilters.getReportFiltersLeadStatusSelected)
        ),
        switchMap(([action, parseUrlType, sort, ownerStatus, leadStatus]) =>
          this.apiService
            .sendRequest({
              method: 'GET',
              requestOptions: {
                params: {
                  parseType: parseUrlType,
                  ...this.handleFilterParameters(sort, ownerStatus, leadStatus)
                },
                responseType: 'text'
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${action.payload}/CSV`
            })
            .pipe(
              map((result) => new walkingFarmReportDetailsActions.ExportCSVSuccess(result)),
              catchError((error) => {
                this.pfHelperService.showSystemError(error.message);
                return of(new walkingFarmReportDetailsActions.ExportCSVFail(error));
              })
            )
        )
      )
  );

  private handleFilterParameters(sort: OwnerStatus, ownerStatus: OwnerStatus, leadStatus: string[]) {
    return {
      ...(sort?.selectedTab ? { sortBy: sort.selectedTab } : {}),
      ...(ownerStatus?.selectedTab ? { filterOwnerStatus: ownerStatus.selectedTab } : {}),
      ...(leadStatus?.length ? { filterLeadStatus: leadStatus.map((leadStatus) => camelCase(leadStatus)) } : {})
    };
  }

  constructor(
    private actions$: Actions,
    private store: Store<fromWalkingFarm.State | fromCalculators.State | fromPropertyData.State>,
    private apiProvider: ApiProvider,
    private pfHelperService: PfHelperService,
    private apiService: ApiService
  ) {}
}
