import { combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { countBy, filter, find, groupBy, isArray, map, orderBy, pick, uniq } from 'lodash-es';

import { REP_DATA_RESPONSE_PROVIDER_ID_LPS } from '../../../../constants/main.constants';
import { FarmSummaryData, PropertyData } from '../../../typings/walking-farm';

import { getAvgValueOfMinMax, getAvgValueOfMinMaxWithinAnYear, getMaxMinValueWithinAnYear } from '../../entities-util';
import * as fromPhoneAndEmailCsv from '../../reducers/walking-farm/export-iap-phone-and-email-csv';
import * as fromExportInAveryLabels from '../../reducers/walking-farm/export-in-avery-labels';
import * as fromExportInCSV from '../../reducers/walking-farm/export-in-csv';
import * as fromFarmAlerts from '../../reducers/walking-farm/farm-alerts';
import * as fromFastFarm from '../../reducers/walking-farm/fast-farm';
import * as fromGetFarmCount from '../../reducers/walking-farm/get-farm-count';
import * as fromPolygon from '../../reducers/walking-farm/polygon-data';
import * as fromReportSummary from '../../reducers/walking-farm/report-summary';
import * as fromSavedFarms from '../../reducers/walking-farm/saved-farms';
import * as fromSearch from '../../reducers/walking-farm/search-in-map';
import * as fromViewFarm from '../../reducers/walking-farm/view-farm';
import * as fromFilters from '../../reducers/walking-farm/walking-farm-filters';
import * as fromReportFilters from '../../reducers/walking-farm/walking-farm-report-filters';
import { getRepDataProviderId } from '../app-config';

export interface WalkingFarmState {
  averyLabelsReport: fromExportInAveryLabels.State;
  farmAlerts: fromFarmAlerts.State;
  farmReportCsv: fromExportInCSV.State;
  fastFarm: fromFastFarm.State;
  filters: fromFilters.State;
  getFarmCount: fromGetFarmCount.State;
  iapPhoneAndEmailCsv: fromPhoneAndEmailCsv.State;
  polygon: fromPolygon.State;
  reportFilters: fromReportFilters.State;
  reportSummary: fromReportSummary.State;
  savedFarms: fromSavedFarms.State;
  searchInMap: fromSearch.State;
  viewFarm: fromViewFarm.State;
}

export interface State {
  walkingFarm: WalkingFarmState;
}

export const reducer = combineReducers({
  averyLabelsReport: fromExportInAveryLabels.reducer,
  farmAlerts: fromFarmAlerts.reducer,
  farmReportCsv: fromExportInCSV.reducer,
  fastFarm: fromFastFarm.reducer,
  filters: fromFilters.reducer,
  getFarmCount: fromGetFarmCount.reducer,
  iapPhoneAndEmailCsv: fromPhoneAndEmailCsv.reducer,
  polygon: fromPolygon.reducer,
  reportFilters: fromReportFilters.reducer,
  reportSummary: fromReportSummary.reducer,
  savedFarms: fromSavedFarms.reducer,
  searchInMap: fromSearch.reducer,
  viewFarm: fromViewFarm.reducer
});

export const getWalkingFarmState = createFeatureSelector<WalkingFarmState>('walkingFarm');
export const getWalkingFarmFiltersState = createSelector(getWalkingFarmState, (state) => state.filters);

export const getFiltersOwnerStatus = createSelector(getWalkingFarmFiltersState, fromFilters.getFiltersOwnerStatus);
export const getFiltersSalesYears = createSelector(getWalkingFarmFiltersState, fromFilters.getFiltersSalesYears);
export const getFiltersPropertyTypes = createSelector(getWalkingFarmFiltersState, fromFilters.getFiltersPropertyTypes);
export const getFiltersPropertyTypesSelected = createSelector(
  getWalkingFarmFiltersState,
  fromFilters.getFiltersPropertyTypesSelected
);
export const getFiltersBedrooms = createSelector(getWalkingFarmFiltersState, fromFilters.getFiltersBedrooms);
export const getFiltersBedroomsSelected = createSelector(
  getWalkingFarmFiltersState,
  fromFilters.getFiltersBedroomsSelected
);
export const getFiltersBathroomsSelected = createSelector(
  getWalkingFarmFiltersState,
  fromFilters.getFiltersBathroomsSelected
);
export const getFiltersArea = createSelector(getWalkingFarmFiltersState, fromFilters.getFiltersArea);

export const getSavedFarmListIndex = createSelector(getWalkingFarmFiltersState, fromFilters.getSavedFarmListIndex);

export const getWalkingFarmReportFiltersState = createSelector(getWalkingFarmState, (state) => state.reportFilters);
export const getReportFiltersSortBy = createSelector(
  getWalkingFarmReportFiltersState,
  fromReportFilters.getReportFiltersSortBy
);
export const getReportFiltersOwnerStatus = createSelector(
  getWalkingFarmReportFiltersState,
  fromReportFilters.getReportFiltersOwnerStatus
);
export const getReportFiltersLeadStatus = createSelector(
  getWalkingFarmReportFiltersState,
  fromReportFilters.getReportFiltersLeadStatus
);
export const getReportFiltersAlertsShown = createSelector(
  getWalkingFarmReportFiltersState,
  fromReportFilters.getReportFiltersAlertsShown
);
export const getReportFiltersLeadStatusSelected = createSelector(
  getWalkingFarmReportFiltersState,
  fromReportFilters.getReportFiltersLeadStatusSelected
);

export const getWalkingFarmReportSelectedFilters = createSelector(
  getReportFiltersSortBy,
  getReportFiltersOwnerStatus,
  getReportFiltersLeadStatusSelected,
  getReportFiltersAlertsShown,
  (sortBy, ownerStatus, leadStatuses, alertsShown) => ({
    selectedAlertsShown: alertsShown,
    selectedLeadStatus: leadStatuses,
    selectedOwnerStatus: ownerStatus ? ownerStatus.selectedTab : 'N/A',
    selectedSortBy: sortBy.selectedTab
  })
);

export const getWalkingFarmSearchState = createSelector(getWalkingFarmState, (state) => state.searchInMap);

export const getWalkingFarmMarkers = createSelector(getWalkingFarmSearchState, fromSearch.getMarkers);
export const getWalkingFarmSearchKey = createSelector(getWalkingFarmSearchState, fromSearch.getSearchKey);

export const getWalkingFarmFarmState = createSelector(getWalkingFarmState, (state) => state.savedFarms);

export const SelectAllFarms = createSelector(getWalkingFarmFarmState, fromSavedFarms.SelectAllFarms);

export const getWalkingFarmSelectedId = createSelector(getWalkingFarmFarmState, fromSavedFarms.getSelectedFarmId);

export const getSelectedFarm = createSelector(getWalkingFarmFarmState, getWalkingFarmSelectedId, (farms, farmId) => {
  return farms.entities[farmId];
});
export const getSelectedFarmReportDate = createSelector(getSelectedFarm, (farm) => {
  return farm?.reportDate || '';
});

export const getFarmById = (id) =>
  createSelector(getWalkingFarmFarmState, (farms) => {
    return farms.entities[id];
  });

export const getWalkingFarmLoading = createSelector(getWalkingFarmFarmState, fromSavedFarms.getFarmLoading);

export const getWalkingFarmLoaded = createSelector(getWalkingFarmFarmState, fromSavedFarms.getFarmLoaded);

export const getWalkingFarmViewFarmState = createSelector(getWalkingFarmState, (state) => state?.viewFarm);

export const getWalkingFarmCredits = createSelector(getWalkingFarmViewFarmState, fromViewFarm.getFarmCredits);

export const getWalkingFarmCreditsMessage = createSelector(
  getRepDataProviderId,
  getWalkingFarmCredits,
  (providerId, credits) => {
    if (providerId !== REP_DATA_RESPONSE_PROVIDER_ID_LPS) {
      return '';
    }

    return credits === 0 ? 'Loading...' : `${credits} credits`;
  }
);

export const getWalkingFarmData = createSelector(getWalkingFarmViewFarmState, fromViewFarm.getFarmData);
export const getFarmAlertsToggle = createSelector(getWalkingFarmViewFarmState, fromViewFarm.getFarmDataHasFarmAlerts);
export const getFarmReportName = createSelector(getWalkingFarmViewFarmState, fromViewFarm.getFarmDataFarmName);
export const getFarmReportIAPData = createSelector(getWalkingFarmViewFarmState, fromViewFarm.getFarmIAPData);
export const getFarmReportId = createSelector(getWalkingFarmViewFarmState, fromViewFarm.getFarmDataFarmId);

export const getSelectedFarmPropertyById = (id) =>
  createSelector(getWalkingFarmData, (farmData) => {
    const result = find(farmData.properties, (prop) => prop.A000_PropertyNumber == id);
    if (result) {
      return result;
    } else {
      return null;
    }
  });

export const getWalkingFarmTurnOver = createSelector(getWalkingFarmViewFarmState, fromViewFarm.getFarmTurnOver);
export const getWalkingFarmRawCoordinates = createSelector(
  getWalkingFarmViewFarmState,
  fromViewFarm.getRawFarmCoordinates
);
export const getWalkingFarmCoordinates = createSelector(getWalkingFarmViewFarmState, fromViewFarm.getFarmCoordinates);

export const getPolygonState = createSelector(getWalkingFarmState, (state) => state.polygon);

export const getWalkingFarmPolygonPoints = createSelector(getPolygonState, fromPolygon.getFarmPolygonPoints);
export const getWalkingFarmPolygonStartedFlag = createSelector(getPolygonState, fromPolygon.getPolygonStartedFlag);
export const getWalkingFarmPolygonCompletedFlag = createSelector(getPolygonState, fromPolygon.getPolygonCompletedFlag);

export const getFarmSummaryData = createSelector(getSelectedFarmReportDate, getSelectedFarm, (reportDate, data) => {
  let result: FarmSummaryData = {
    neighborhoodReportData: null,
    saleHistoryReportData: null
  };

  if (data) {
    const properties = isArray(data.properties) ? data.properties : [];

    const countOccupancy = countBy(properties, (property) =>
      property.A040_OwnerOccupied === 'Y' ? 'occupied' : 'absentee'
    );
    const ownerOccupiedCount = countOccupancy.occupied ?? 0;
    const ownerAbsenteeCount = countOccupancy.absentee ?? 0;

    const ownerOccupiedPercent =
      ownerOccupiedCount > 0 ? Math.round((ownerOccupiedCount / properties.length) * 100 * 10) / 10 : 0;
    const ownerOccupiedPercentData = ownerOccupiedPercent < 100 ? ownerOccupiedPercent : 99.999;

    const salesTurnover = Number(data.turnoverRate.replace('%', ''));

    result = {
      neighborhoodReportData: {
        chart: {
          chartData: {
            data: [ownerOccupiedPercentData, 100 - Number(ownerOccupiedPercentData)],
            labels: ['Owners Occupied', 'Absentee Owners']
          },
          hasCustomLegend: true,
          legend: {
            data: [
              { label: 'Owners Occupied', value: ownerOccupiedCount.toString() },
              { label: 'Absentee Owners', value: ownerAbsenteeCount.toString() }
            ]
          },
          middleInfo: { description: `${ownerOccupiedPercent || 0} %`, title: 'Occupied: ' }
        },
        neighborhood: {
          avgSf: {
            format: 'number',
            label: 'Median FSF',
            value: getAvgValueOfMinMax(data.properties, 'A022_BuildingArea', true)
          },
          bathrooms: getAvgValueOfMinMax(data.properties, 'A025_Bathrooms', true),
          bedrooms: getAvgValueOfMinMax(data.properties, 'A024_Bedrooms', true)
        },
        turnover: ''
      },
      saleHistoryReportData: {
        chart: {
          chartData: {
            data: [salesTurnover, 100 - Number(salesTurnover)],
            labels: ['Sales', '']
          },
          hasCustomLegend: true,
          legend: {
            data: [
              {
                label: 'Sales: ',
                value: Math.floor((Number(salesTurnover) / 100) * data.properties?.length).toString()
              }
            ]
          },
          middleInfo: { description: `${salesTurnover || 0} %`, title: 'Turnover: ' }
        },
        propertiesCount: data.propertyCount,
        saleHistory: {
          avgPrice: {
            format: 'currency',
            label: 'Median Price',
            value: getAvgValueOfMinMaxWithinAnYear(data.properties, 'A029_SalesPrice', reportDate, 'A030_SaleDate')
          },
          highest: {
            format: 'currency',
            label: 'Highest Sale Price',
            value: getMaxMinValueWithinAnYear(data.properties, 'A029_SalesPrice', 'max', reportDate, 'A030_SaleDate')
          },
          lowest: {
            format: 'currency',
            label: 'Lowest Sale Price',
            value: getMaxMinValueWithinAnYear(data.properties, 'A029_SalesPrice', 'min', reportDate, 'A030_SaleDate')
          }
        },
        turnover: salesTurnover
      }
    };
  }

  return result;
});

export const getFarmAlertsState = createSelector(getWalkingFarmState, (state) => state.farmAlerts);

export const getWalkingFarmAlertsData = createSelector(getFarmAlertsState, fromFarmAlerts.getFarmAlertsData);
export const getFarmAlertsLatestAlertDate = createSelector(
  getFarmAlertsState,
  fromFarmAlerts.getFarmAlertsLatestAlertDate
);
export const getSelectedFarmAlertsPropertyIds = createSelector(getWalkingFarmAlertsData, (alertsData) => {
  if (alertsData.length) {
    const filtered = alertsData.filter((alert) => !alert.isRead);
    return uniq(filtered.map((alert) => alert.propertyNumber.toString()));
  }
  return [];
});
export const getWalkingFarmAlertsPending = createSelector(getFarmAlertsState, fromFarmAlerts.getFarmAlertsPending);

export const getFastFarmState = createSelector(getWalkingFarmState, (state) => state.fastFarm);

export const getFastFarmMarker = createSelector(getFastFarmState, fromFastFarm.getFastFarmMarker);

export const getFastFarmRadius = createSelector(getFastFarmState, fromFastFarm.getFastFarmRadius);

export const getFarmCountState = createSelector(getWalkingFarmState, (state) => state.getFarmCount);

export const getFarmCountSucceeded = createSelector(getFarmCountState, fromGetFarmCount.getFarmCountSucceeded);

export const getFarmCountPending = createSelector(getFarmCountState, fromGetFarmCount.getFarmCountPending);

export const getFarmAlertsComputedData = createSelector(
  getWalkingFarmData,
  getWalkingFarmAlertsData,
  (farmData, alerts) => {
    if (!farmData?.properties?.length) {
      return [];
    }

    const grouped = groupBy(orderBy(alerts, 'date', 'desc'), 'propertyNumber');

    const mapped = map(grouped, (value, key) => ({
      addressData: getPropertyValueFromCollection(farmData.properties, 'A000_PropertyNumber', key, [
        'A002_HouseNumber',
        'A004_StreetName',
        'A005_UnitNumber',
        'A008_Zip4',
        'A007_ZipCode',
        'A037_ParcelNumber'
      ]),
      data: value
    }));

    return orderBy(mapped, 'address', 'asc');
  }
);

export const getPropertyAlertsComputedDataById = (id: string) =>
  createSelector(getWalkingFarmAlertsData, (alerts) => {
    let result = [];
    if (!alerts.length) {
      return result;
    }
    const filtered = filter(alerts, (alert) => alert.propertyNumber == id);
    if (filtered.length) {
      result = orderBy(filtered, 'date', 'desc');
    }
    return result;
  });

function getPropertyValueFromCollection(
  collection: PropertyData[],
  propName: string,
  propValue: string,
  valuesToFind: string[]
) {
  const item = find(collection, (elem) => elem[`${propName}`] == propValue);
  return pick(item, valuesToFind);
}

export const getAveryLabelsReportState = createSelector(getWalkingFarmState, (state) => state.averyLabelsReport);
export const getAveryLabelsPdfDocData = createSelector(
  getAveryLabelsReportState,
  fromExportInAveryLabels.getAveryLabelsPdfDocData
);

export const getExportInCSVState = createSelector(getWalkingFarmState, (state) => state.farmReportCsv);

export const getFarmReportCsvData = createSelector(getExportInCSVState, fromExportInCSV.getFarmReportCsvData);

export const getReportSummaryState = createSelector(getWalkingFarmState, (state) => state.reportSummary);

export const getReportSummaryListIndex = createSelector(
  getReportSummaryState,
  fromReportSummary.getReportSummaryListIndex
);

export const getMarkerType = createSelector(getReportSummaryState, fromReportSummary.getMarkerType);

export const getActiveTab = createSelector(getReportSummaryState, fromReportSummary.getActiveTab);

const iapPhoneAndEmailCsvStateSelector = createSelector(getWalkingFarmState, (state) => state.iapPhoneAndEmailCsv);

export const getIapPhoneAndEmailCsvData = createSelector(
  iapPhoneAndEmailCsvStateSelector,
  fromPhoneAndEmailCsv.getIapPhoneAndEmailCsvData
);

export const getIapPhoneAndEmailCsvError = createSelector(
  iapPhoneAndEmailCsvStateSelector,
  fromPhoneAndEmailCsv.getIapPhoneAndEmailCsvError
);
