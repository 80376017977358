import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ADMIN_PORTAL_CONFIG, NETCALCS_REPORTS_CONFIG } from '../../../environments/environment';
import { adminPortalDependencies, adminPortalEndpoints } from '../../constants/admin-portal';
import { netCalcsEndpoints, netCalcsReportsDependencies } from '../../constants/netcalcs-reports';

import { ApiService } from '../../common/services/api/api.service';

import * as calculatorsAndReportsActions from '../actions/calculators/calculators-and-reports';
import * as calculatorsActions from '../actions/calculators/calculators-and-reports';
import * as fromAppConfig from '../selectors/app-config';
import * as fromAppSettings from '../selectors/app-settings';

@Injectable()
export class CalculatorsEffects {
  getAmortizationCalculatorData = createEffect(() =>
    this.actions$
      .pipe(
        ofType<calculatorsAndReportsActions.GetAmortizationData>(
          calculatorsAndReportsActions.CalculatorsReportsActionTypes.GetAmortizationData
        )
      )
      .pipe(
        switchMap((data) => {
          return this.apiService
            .sendRequest({
              method: 'GET',
              requestOptions: {
                params: {
                  amount: data.payload?.amount,
                  interestRate: data.payload?.interestRate,
                  term: data.payload?.term
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.calculators}/Amortization`
            })
            .pipe(
              map((amortizationData) => new calculatorsActions.GetAmortizationDataSuccess(amortizationData)),
              catchError(() => of(new calculatorsActions.GetAmortizationDataFail()))
            );
        })
      )
  );

  getAmortizationCalculatorDataFail = createEffect(
    () =>
      this.actions$.pipe(
        ofType<calculatorsAndReportsActions.GetAmortizationDataFail>(
          calculatorsAndReportsActions.CalculatorsReportsActionTypes.GetAmortizationDataFail
        ),
        map(async () => {
          await this.apiService.displayAlert(
            adminPortalDependencies.error.calculatorsAmortization.message,
            adminPortalDependencies.error.calculatorsAmortization.title
          );
        })
      ),
    { dispatch: false }
  );

  getCalculatorPdfReport = createEffect(() =>
    this.actions$
      .pipe(
        ofType<calculatorsActions.SetPdfReportData>(calculatorsActions.CalculatorsReportsActionTypes.SetPdfReportData)
      )
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getGeneralInfoAuthData),
          this.store.select(fromAppSettings.getAppSettingsUserProfileData)
        ),
        filter(([_action, authData, user]) => !!authData.repCode && !isNil(user)),
        map(([action, authData, user]) => ({
          data: action.payload,
          repCode: authData.repCode,
          userId: user.id
        })),
        switchMap(({ data, repCode, userId }) => {
          const calculatorEndpoint = () => {
            let result = '';

            switch (data.reportType) {
              case 'piti':
                result = `${netCalcsEndpoints.report}/Calculator/PITI`;
                break;

              case 'affordability':
                result = `${netCalcsEndpoints.report}/Calculator/Affordability`;
                break;

              case 'amortization':
                result = `${netCalcsEndpoints.report}/Calculator/Amortization`;
                break;

              default:
                break;
            }

            return result;
          };

          return this.apiService
            .sendRequest(
              {
                method: 'POST',
                requestOptions: {
                  body: {
                    ...data.calculatorData,
                    parseUserId: userId,
                    repCode
                  },
                  responseType: 'blob'
                },
                url: `${NETCALCS_REPORTS_CONFIG.apiUrl}/v3/${calculatorEndpoint()}`
              },
              netCalcsReportsDependencies.apiName
            )
            .pipe(
              map((reportPDFBlob) => new calculatorsActions.GetPdfReportDataSuccess(reportPDFBlob)),
              catchError((error) => of(new calculatorsActions.GetPdfReportDataFail(error)))
            );
        })
      )
  );

  getCalculatorPdfReportFail = createEffect(
    () =>
      this.actions$.pipe(
        ofType<calculatorsActions.GetPdfReportDataFail>(
          calculatorsActions.CalculatorsReportsActionTypes.GetPdfReportDataFail
        ),
        map(async () => {
          await this.apiService.displayAlert(
            netCalcsReportsDependencies.error.reportCalculator.message,
            netCalcsReportsDependencies.error.reportCalculator.title
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromAppConfig.State>,
    private apiService: ApiService
  ) {}
}
