import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { has } from 'lodash-es';
import { from, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { ADMIN_PORTAL_CONFIG } from '../../../environments/environment';
import { adminPortalEndpoints } from '../../constants/admin-portal';
import { ReferralCodeDataModel } from '../../typings/app';

import { ApiProvider } from '../../providers/api.service';
import { PfHelperService } from '../../providers/pf-helper-service.service';
import { ApiService } from '../../common/services/api/api.service';
import { LoadingService } from '../../common/services/loading/loading.service';
import { SecureStorageService } from '../../common/services/secure-storage/secure-storage.service';

import * as fromAppConfigGeneralInfoActions from '../actions/app-config/general-info';
import * as appConfigGeneralInfoAction from '../actions/app-config/general-info';
import * as generalInfoAction from '../actions/app-config/general-info';
import * as appConfigActions from '../actions/app-config/general-info';
import * as fromAppConfigMapDataActions from '../actions/app-config/map-data';
import * as appSettingsSaleInfoAction from '../actions/app-settings/sale-info';
import * as appSettingsUserProfileAction from '../actions/app-settings/user-profile';
import * as userProfileActions from '../actions/app-settings/user-profile';
import * as fromUserProfileAction from '../actions/app-settings/user-profile';
import * as fromMonetizationActions from '../actions/monetization.actions';
import * as appParseAction from '../actions/parse/parse-implementation';
import * as propertySearchAction from '../actions/property-search/search-types';
import * as fromAppConfig from '../selectors/app-config';
import * as fromAppConfigSelectors from '../selectors/app-config';
import * as fromAppSettings from '../selectors/app-settings';

@Injectable()
export class AppSettingsEffects {
  getGeneralInfo$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<appConfigGeneralInfoAction.GetAppSettingsData>(
          appConfigGeneralInfoAction.GeneralConfigActionTypes.GetAppSettingsData
        )
      )
      .pipe(
        withLatestFrom(this.store.select(fromAppConfigSelectors.getGeneralInfoAuthData)),
        switchMap(([action, authData]) =>
          this.apiService
            .sendRequest({
              method: 'GET',
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.systemConfigurations}/Mobile/${authData.repCode}`
            })
            .pipe(
              switchMap((referralCodeData: ReferralCodeDataModel) => {
                const dispatchActions: Action[] = [];

                if (referralCodeData.validCode) {
                  const {
                    organization,
                    parse,
                    dataPortal,
                    hideContactCard,
                    office,
                    titleRep,
                    products,
                    provider,
                    subscriptionPlan
                  } = referralCodeData;

                  dispatchActions.push(fromMonetizationActions.loadProductsSuccess({ products }));

                  if (has(action, 'payload.newAuthData')) {
                    dispatchActions.push(
                      new appConfigActions.UpdateAuthData({
                        ...action.payload.newAuthData,
                        password: action.payload.newAuthData.password
                      })
                    );
                  }

                  if (has(action, 'payload.refreshUser')) {
                    dispatchActions.push(new generalInfoAction.SaveDataInInternalStorage());
                  }

                  dispatchActions.push(
                    new appConfigGeneralInfoAction.SetRepDataProvider({
                      calculatorsEnabled: office.calculatorsEnabled,
                      dataPortalName: dataPortal.name,
                      hideContactCard,
                      inAppProducts: products,
                      isNetSheetEnabled: office.netSheetEnabled,
                      orderTitleEmail: office.orderTitleEmail,
                      phoneAndEmailPurchase: office.phoneAndEmailPurchase,
                      primaryPhoneType: titleRep.primaryPhoneType,
                      providerId: provider.name,
                      showDocLinks: office.showDocumentLinks,
                      subscriptionPlan,
                      walkingFarmEnabled: office.walkingFarmEnabled
                    }),
                    new appSettingsSaleInfoAction.GetSalesInformationSuccess({
                      agentPhotoUrl: titleRep.photoUrl,
                      company: organization.name,
                      contactCardImageUrl: office.contactCardImageUrl,
                      customerServiceNumber: office.customerServiceNumber,
                      email: titleRep.email,
                      firstName: titleRep.firstName,
                      lastName: titleRep.lastName,
                      phone: titleRep.phone,
                      primaryPhoneType: titleRep.primaryPhoneType
                    }),
                    new generalInfoAction.UpdateDeviceData({
                      appName: organization.applicationName
                    }),
                    new generalInfoAction.SetAppInfo({
                      baseColors: {
                        chartsColorPrimary: organization.whiteLabeling.chartsColorPrimary,
                        colors: {
                          danger: organization.whiteLabeling.danger,
                          dark: organization.whiteLabeling.dark,
                          light: organization.whiteLabeling.light,
                          medium: organization.whiteLabeling.medium,
                          primary: organization.whiteLabeling.primary,
                          secondary: organization.whiteLabeling.secondary,
                          success: organization.whiteLabeling.success,
                          warn: organization.whiteLabeling.warn
                        }
                      },
                      baseUrls: {
                        alternateAppIconUrl: organization.alternateAppIconPreviewUrl,
                        logoUrl: organization.logo
                      },
                      companyConfig: {
                        alternateAppIconName: organization.alternateAppIconName,
                        autoSubscribeFarmAlerts: organization.autoSubscribeFarmAlerts,
                        consolidatedParseAppKey: parse.appKey,
                        farmAlertsEnabled: organization.enableFarmAlerts,
                        parseUrlType: parse.type,
                        showAreaSalesProfileItem: organization.showAreaSalesProfileItem,
                        showComparableSalesProfileItem: organization.showComparableSalesProfileItem,
                        showTransactionHistoryProfileItem: organization.showTransactionHistoryProfileItem,
                        targetName: organization.applicationName,
                        titleCompanyShortName: organization.shortName || organization.applicationName
                      }
                    }),
                    new appConfigGeneralInfoAction.GetAppSettingsDataSuccess(referralCodeData)
                  );
                } else {
                  dispatchActions.push(
                    new appConfigGeneralInfoAction.GetAppSettingsDataFail(new Error('The Referral Code is invalid.'))
                  );
                }

                return dispatchActions;
              }),
              catchError((error) => of(new appConfigGeneralInfoAction.GetAppSettingsDataFail(error)))
            )
        )
      )
  );

  handleAppSettingsDataFail = createEffect(
    () =>
      this.actions$
        .pipe(
          ofType<appConfigGeneralInfoAction.GetAppSettingsDataFail>(
            appConfigGeneralInfoAction.GeneralConfigActionTypes.GetAppSettingsDataFail
          )
        )
        .pipe(
          map(async (action) => {
            let message = 'Failed to get Application Data.';
            let title = 'Error';

            if (action.payload?.message?.includes('Referral Code')) {
              message += ' The Referral Code is invalid.';
              title = 'Referral Code Error';
            }

            await this.pfHelperService.showSystemError(message, false, title);
          })
        ),
    { dispatch: false }
  );

  verifyUser = createEffect(() =>
    this.actions$
      .pipe(
        ofType<appSettingsUserProfileAction.VerifyUser>(appSettingsUserProfileAction.UserProfileActionTypes.VerifyUser)
      )
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getGeneralInfoDeviceData)),
        switchMap(([action, deviceData]) =>
          this.oldApiService
            .getData('authenticateUser', {
              deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
              office: deviceData.officeCode,
              password: action.payload.password,
              username: action.payload.username
            })
            .pipe(
              switchMap(() => [
                new appSettingsUserProfileAction.AuthenticateUserSuccess({
                  password: action.payload.password,
                  username: action.payload.username
                }),
                new appSettingsUserProfileAction.VerifyUserSuccess(action.payload),
                new appParseAction.GetRegisteredUser({ email: action.payload.email })
              ]),
              catchError((err) => of(new appSettingsUserProfileAction.VerifyUserFail(err)))
            )
        )
      )
  );

  authenticateUser$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<appSettingsUserProfileAction.AuthenticateUser>(
          appSettingsUserProfileAction.UserProfileActionTypes.AuthenticateUser
        )
      )
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getGeneralInfoDeviceData)),
        switchMap(([action, deviceData]) =>
          this.oldApiService
            .getData('authenticateUser', {
              deviceData: this.pfHelperService.getComputeDeviceData(deviceData),
              office: deviceData.officeCode,
              password: action.payload.password,
              username: action.payload.username
            })
            .pipe(
              switchMap(() => [
                new appSettingsUserProfileAction.AuthenticateUserSuccess(action.payload),
                new appParseAction.CreateUser()
              ]),
              catchError((error) => of(new appSettingsUserProfileAction.AuthenticateUserFail(error)))
            )
        )
      )
  );

  logout = createEffect(() =>
    this.actions$
      .pipe(
        ofType<appSettingsUserProfileAction.LogoutUser>(appSettingsUserProfileAction.UserProfileActionTypes.LogoutUser)
      )
      .pipe(
        switchMap(() => {
          return [
            new fromAppConfigGeneralInfoActions.ResetGeneralInfo(),
            new fromUserProfileAction.ResetUserProfile(),
            new fromAppConfigMapDataActions.GetInitialLocation(),
            new fromUserProfileAction.LogoutUserSuccess()
          ];
        })
      )
  );

  logoutSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType<appSettingsUserProfileAction.LogoutUserSuccess>(
          appSettingsUserProfileAction.UserProfileActionTypes.LogoutUserSuccess
        ),
        switchMap(() => {
          const loadingInstanceID = uuidV4();
          this.loadingService.setLoading(loadingInstanceID, true);
          return of(true).pipe(
            delay(500),
            switchMap(() =>
              from(this.secureStorageService.clear()).pipe(
                switchMap(() => {
                  this.loadingService.setLoading(loadingInstanceID, false);
                  return this.navController.navigateRoot(['/', 'welcome']);
                })
              )
            )
          );
        })
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<appSettingsUserProfileAction.ResetPassword>(
        appSettingsUserProfileAction.UserProfileActionTypes.ResetPassword
      ),
      filter((action) => !!action.payload),
      withLatestFrom(this.store.select(fromAppConfig.getGeneralInfoDeviceData)),
      switchMap((data) => {
        return this.oldApiService
          .getData('changePassword', {
            deviceData: this.pfHelperService.getComputeDeviceData(data[1]),
            newPassword: data[0].payload.newPassword,
            oldPassword: data[0].payload.oldPassword,
            user: data[0].payload.username
          })
          .pipe(
            switchMap(() => [
              new appSettingsUserProfileAction.ResetPasswordSuccess({
                newPassword: data[0].payload.newPassword,
                username: data[0].payload.username
              })
            ]),
            catchError((error) => of(new appSettingsUserProfileAction.ResetPasswordFail(error)))
          );
      })
    )
  );

  resetPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<appSettingsUserProfileAction.ResetPasswordSuccess>(
          appSettingsUserProfileAction.UserProfileActionTypes.ResetPasswordSuccess
        ),

        concatMap(async (action) => {
          this.store.dispatch(new appConfigActions.UpdatePassword(btoa(action.payload.newPassword)));
          this.store.dispatch(new propertySearchAction.ResetSearchError());
          this.store.dispatch(new propertySearchAction.ResetSearchTypes());
          this.store.dispatch(new userProfileActions.ChangeExpiredPassword(false));

          if (action.payload.username) {
            await this.navController.navigateRoot(['/', 'home', 'search']);
          } else {
            await this.navController.navigateRoot(['/', 'welcome']);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private loadingService: LoadingService,
    private navController: NavController,
    private oldApiService: ApiProvider,
    private pfHelperService: PfHelperService,
    private secureStorageService: SecureStorageService,
    private store: Store<fromAppSettings.State>
  ) {}
}
