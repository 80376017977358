import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PURCHASE_TYPE, Purchases, PurchasesError } from '@awesome-cordova-plugins/purchases/ngx';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { StripeService } from 'ngx-stripe';
import { from, of } from 'rxjs';
import { catchError, concatMap, delay, filter, finalize, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { ADMIN_PORTAL_CONFIG, containerName, REVENUE_CAT_PUBLIC_KEY } from '../../../environments/environment';
import { adminPortalEndpoints } from '../../constants/admin-portal';
import { MonetizationPaymentRedirectTypes } from '../../enums/monetization';
import {
  MonetizationRevenueCatWebOfferings,
  MonetizationRevenueCatWebPurchaserInfo,
  MonetizationStripeCheckoutSession,
  MonetizationStripeProduct,
  MonetizationStripeSubscription,
  VirtualCurrencyPreview,
  VirtualCurrencyPurchaseResponse
} from '../../typings/monetization';

import { ApiService } from '../../common/services/api/api.service';
import { LoadingService } from '../../common/services/loading/loading.service';
import { MonetizationService } from '../../common/services/monetization/monetization.service';
import { PlatformDetectService } from '../../common/services/platform-detect/platform-detect.service';

import * as fromMonetizationActions from '../actions/monetization.actions';
import * as parseActions from '../actions/parse/parse-implementation';
import * as appParseAction from '../actions/parse/parse-implementation';
import * as fromAppConfig from '../selectors/app-config';
import * as fromAppSettingsSelectors from '../selectors/app-settings';
import * as fromAppSettings from '../selectors/app-settings';
import * as fromMonetizationSelectors from '../selectors/monetization.selectors';

@Injectable()
export class MonetizationEffects {
  loadPurchaserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadPurchaserInfo),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map((data) => ({
        isMobilePlatform: data[1]
      })),
      map(({ isMobilePlatform }) =>
        isMobilePlatform
          ? fromMonetizationActions.loadMobilePurchaserInfo()
          : fromMonetizationActions.loadWebPurchaserInfo()
      )
    )
  );

  loadMobilePurchaserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadMobilePurchaserInfo),
      switchMap(() => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);
        return from(this.purchases.getCustomerInfo()).pipe(
          map((customerInfo) => fromMonetizationActions.loadMobilePurchaserInfoSuccess({ customerInfo })),
          finalize(() => {
            this.loadingService.setLoading(loadingInstanceID, false);
          }),
          catchError(async (purchasesError: PurchasesError) => {
            const error = new Error(purchasesError.message);
            error.name = purchasesError.readableErrorCode;
            error.stack = purchasesError.underlyingErrorMessage;
            await this.monetizationService.handleError({
              error,
              message: `Failed to load purchaser information${this.monetizationService.getPurchasesErrorMessageDetails(
                purchasesError
              )}.`,
              subHeader: 'RevenueCat Initialization'
            });
            return fromMonetizationActions.loadMobilePurchaserInfoFailure({ error });
          })
        );
      })
    )
  );

  loadWebPurchaserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadWebPurchaserInfo),
      withLatestFrom(this.store.select(fromAppSettings.getAppSettingsUserProfileData)),
      map((data) => ({
        user: data[1]
      })),
      filter(({ user }) => !isNil(user?.id)),
      switchMap(({ user }) => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);
        return this.http
          .get(`https://api.revenuecat.com/v1/subscribers/${user.id}`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${REVENUE_CAT_PUBLIC_KEY}`,
              'Content-Type': 'application/json'
            }
          })
          .pipe(
            map((purchaserInfo: MonetizationRevenueCatWebPurchaserInfo) =>
              fromMonetizationActions.loadWebPurchaserInfoSuccess({
                purchaserInfo
              })
            ),
            finalize(() => {
              this.loadingService.setLoading(loadingInstanceID, false);
            }),
            catchError(async (error) => {
              await this.monetizationService.handleError({
                error,
                message: `Failed to load purchaser information (${error.message}).`,
                subHeader: 'RevenueCat Initialization'
              });
              return fromMonetizationActions.loadWebPurchaserInfoFailure({ error });
            })
          );
      })
    )
  );

  loadOfferingsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadOfferingsInfo),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map((data) => ({
        isMobilePlatform: data[1]
      })),
      map(({ isMobilePlatform }) =>
        isMobilePlatform
          ? fromMonetizationActions.loadMobileOfferingsInfo()
          : fromMonetizationActions.loadWebOfferingsInfo()
      )
    )
  );

  loadMobileOfferingsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadMobileOfferingsInfo),
      withLatestFrom(this.store.select(fromAppConfig.getInAppProducts)),
      map((data) => ({
        inAppProducts: data[1]
      })),
      switchMap(({ inAppProducts }) => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);

        return from(this.purchases.getOfferings()).pipe(
          map((purchasesOfferings) =>
            fromMonetizationActions.loadMobileOfferingsInfoSuccess({ inAppProducts, purchasesOfferings })
          ),
          finalize(() => {
            this.loadingService.setLoading(loadingInstanceID, false);
          }),
          catchError(async (purchasesError: PurchasesError) => {
            const error = new Error(purchasesError.message);
            error.name = purchasesError.readableErrorCode;
            error.stack = purchasesError.underlyingErrorMessage;
            await this.monetizationService.handleError({
              error,
              message: `Failed to load offerings information${this.monetizationService.getPurchasesErrorMessageDetails(
                purchasesError
              )}.`,
              subHeader: 'RevenueCat Initialization'
            });
            return fromMonetizationActions.loadMobileOfferingsInfoFailure({ error });
          })
        );
      })
    )
  );

  loadWebOfferingsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadWebOfferingsInfo),
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getInAppProducts)
      ),
      map((data) => ({
        inAppProducts: data[2],
        user: data[1]
      })),
      filter(({ user }) => !isNil(user?.id)),
      switchMap(({ user, inAppProducts }) => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);
        return this.http
          .get(`https://api.revenuecat.com/v1/subscribers/${user.id}/offerings`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${REVENUE_CAT_PUBLIC_KEY}`,
              'Content-Type': 'application/json',
              'X-Platform': 'stripe'
            }
          })
          .pipe(
            map((offerings: MonetizationRevenueCatWebOfferings) =>
              fromMonetizationActions.loadWebOfferingsInfoSuccess({
                inAppProducts,
                offerings
              })
            ),
            catchError(async (error) => {
              await this.monetizationService.handleError({
                error,
                message: `Failed to load offerings information (${error.message}).`,
                subHeader: 'RevenueCat Initialization'
              });
              return fromMonetizationActions.loadWebOfferingsInfoFailure(error);
            }),
            finalize(() => {
              this.loadingService.setLoading(loadingInstanceID, false);
            })
          );
      })
    )
  );

  loadPrimarySubscriptionPackagePlatformProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadPrimarySubscriptionPackagePlatformProductDetails),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map((data) => ({
        isMobilePlatform: data[1]
      })),
      concatMap(({ isMobilePlatform }) => [
        fromMonetizationActions.loadPrimarySubscriptionPackageCommonPlatformProductDetails(),
        isMobilePlatform
          ? fromMonetizationActions.loadPrimarySubscriptionPackageMobilePlatformProductDetails()
          : fromMonetizationActions.loadPrimarySubscriptionPackageWebPlatformProductDetails()
      ])
    )
  );

  loadPrimarySubscriptionPackageCommonPlatformProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadPrimarySubscriptionPackageCommonPlatformProductDetails),
      withLatestFrom(
        this.store.select(fromMonetizationSelectors.selectPrimarySubscriptionPackagePlatformProductIdentifier)
      ),
      map((data) => ({
        platformProductIdentifier: data[1]
      })),
      switchMap(({ platformProductIdentifier }) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Subscriptions/Details/${platformProductIdentifier}`
          })
          .pipe(
            map((data) =>
              fromMonetizationActions.loadPrimarySubscriptionPackageCommonPlatformProductDetailsSuccess({
                description: data.description
              })
            ),
            catchError(async (error) => {
              await this.monetizationService.handleError({
                error,
                message: `Failed to load product details (${error.message}).`,
                subHeader: 'Product Information'
              });
              return fromMonetizationActions.loadPrimarySubscriptionPackageCommonPlatformProductDetailsFailure(error);
            })
          )
      )
    )
  );

  loadPrimarySubscriptionPackageWebPlatformProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadPrimarySubscriptionPackageWebPlatformProductDetails),
      withLatestFrom(
        this.store.select(fromMonetizationSelectors.selectPrimarySubscriptionPackagePlatformProductIdentifier)
      ),
      map((data) => ({
        platformProductIdentifier: data[1]
      })),
      switchMap(({ platformProductIdentifier }) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: {
                ids: [platformProductIdentifier]
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Products`
          })
          .pipe(
            map((monetizationStripeProducts: MonetizationStripeProduct[]) => {
              if (!monetizationStripeProducts.length) {
                throw new Error('Product not found');
              }

              return fromMonetizationActions.loadPrimarySubscriptionPackageWebPlatformProductDetailsSuccess({
                monetizationStripeProduct: monetizationStripeProducts[0]
              });
            }),
            catchError(async (error) => {
              await this.monetizationService.handleError({
                error,
                message: `Failed to load Stripe product details (${error.message}).`,
                subHeader: 'Stripe Product Information'
              });
              return fromMonetizationActions.loadPrimarySubscriptionPackageWebPlatformProductDetailsFailure({ error });
            })
          )
      )
    )
  );

  purchaseSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.purchaseSubscription),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map(([action, isMobilePlatform]) =>
        isMobilePlatform
          ? fromMonetizationActions.purchaseMobileSubscription({
              monetizationPackage: action.monetizationPackage
            })
          : fromMonetizationActions.purchaseWebSubscription({
              monetizationPackage: action.monetizationPackage,
              redirectType: action.redirectType
            })
      )
    )
  );

  purchaseMobileSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.purchaseMobileSubscription),
      switchMap((action) => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);
        return from(
          this.purchases.purchaseProduct(action.monetizationPackage.platformProductIdentifier, null, PURCHASE_TYPE.SUBS)
        ).pipe(
          switchMap((response) => [
            fromMonetizationActions.purchaseMobileSubscriptionSuccess({ customerInfo: response.customerInfo }),
            fromMonetizationActions.loadBadgerBucks({
              internalIdentifier: action.monetizationPackage.internalIdentifier
            })
          ]),
          finalize(() => {
            this.loadingService.setLoading(loadingInstanceID, false);
          }),
          catchError(async (error) => {
            if (!error.userCancelled) {
              await this.monetizationService.handleError({
                error,
                message: `Failed to subscribe the user${this.monetizationService.getPurchasesErrorMessageDetails(
                  error
                )}.`,
                subHeader: 'Subscription Failed'
              });
            }
            return fromMonetizationActions.purchaseMobileSubscriptionFailure({ error });
          })
        );
      })
    )
  );

  purchaseWebSubscription$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMonetizationActions.purchaseWebSubscription),
        withLatestFrom(this.store.select(fromAppSettingsSelectors.getAppSettingsUserProfileData)),
        map((data) => ({
          monetizationPackage: data[0].monetizationPackage,
          redirectType: data[0].redirectType,
          user: data[1]
        })),
        filter(({ user }) => !isNil(user)),
        delay(500),
        switchMap(({ monetizationPackage, redirectType, user }) =>
          this.apiService
            .sendRequest({
              method: 'POST',
              requestOptions: {
                body: {
                  clientReferenceId: user.id,
                  container: containerName,
                  customerEmail: user.email,
                  mode: 'subscription',
                  priceId: monetizationPackage.product.price.id,
                  uiCancelUrl: `${location.protocol}//${window.location.host}/#/payment/${redirectType}/redirect/${MonetizationPaymentRedirectTypes.Cancel}`,
                  uiSuccessUrl: `${location.protocol}//${window.location.host}/#/payment/${redirectType}/redirect/${MonetizationPaymentRedirectTypes.Success}`
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Checkout/Sessions`
            })
            .pipe(
              concatMap((stripeCheckoutSession: MonetizationStripeCheckoutSession) =>
                this.stripeService.redirectToCheckout({ sessionId: stripeCheckoutSession.id })
              ),
              catchError(async (error) => {
                await this.monetizationService.handleError({
                  error,
                  message: `Failed to perform purchase (${error.message}).`,
                  subHeader: 'Stripe Checkout'
                });
                return fromMonetizationActions.purchaseWebSubscriptionFailure({
                  error
                });
              })
            )
        )
      ),
    {
      dispatch: false
    }
  );

  cancelWebSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.cancelWebSubscription),
      switchMap(({ id }) =>
        this.apiService
          .sendRequest({
            method: 'DELETE',
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Subscriptions/${id}`
          })
          .pipe(
            concatMap(() => [
              fromMonetizationActions.cancelWebSubscriptionSuccess(),
              fromMonetizationActions.loadWebSubscriptions()
            ]),
            catchError(async (error) => {
              await this.monetizationService.handleError({
                error,
                message: `Failed to cancel subscription (${error.message}).`,
                subHeader: 'Stripe Cancel Subscription'
              });
              return fromMonetizationActions.cancelWebSubscriptionFailure(error);
            })
          )
      )
    )
  );

  loadWebSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadWebSubscriptions),
      withLatestFrom(this.store.select(fromAppSettingsSelectors.getAppSettingsUserProfileData)),
      map((data) => ({
        user: data[1]
      })),
      filter(({ user }) => !isNil(user?.email)),
      switchMap(({ user }) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Subscriptions/${user.email}`
          })
          .pipe(
            map((stripeSubscriptions: MonetizationStripeSubscription[]) =>
              fromMonetizationActions.loadWebSubscriptionsSuccess({
                stripeSubscriptions
              })
            ),
            catchError(async (error) => {
              await this.monetizationService.handleError({
                error,
                message: `Failed to load Stripe subscriptions (${error.message}).`,
                subHeader: 'Stripe Subscriptions List'
              });
              return fromMonetizationActions.loadWebSubscriptionsFailure(error);
            })
          )
      )
    )
  );

  purchaseProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.purchaseProduct),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map(([action, isMobilePlatform]) =>
        isMobilePlatform
          ? fromMonetizationActions.purchaseMobileProduct({
              monetizationPackage: action.monetizationPackage
            })
          : fromMonetizationActions.purchaseWebProduct({
              monetizationPackage: action.monetizationPackage,
              redirectType: action.redirectType
            })
      )
    )
  );

  purchaseMobileProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.purchaseMobileProduct),
      switchMap((action) => {
        const loadingInstanceID = uuidV4();
        this.loadingService.setLoading(loadingInstanceID, true);

        return from(
          this.purchases.purchaseProduct(
            action.monetizationPackage.platformProductIdentifier,
            null,
            PURCHASE_TYPE.INAPP
          )
        ).pipe(
          switchMap((response) => [
            fromMonetizationActions.purchaseMobileProductSuccess({ customerInfo: response.customerInfo }),
            fromMonetizationActions.loadBadgerBucks({
              internalIdentifier: action.monetizationPackage.internalIdentifier
            })
          ]),
          finalize(() => {
            this.loadingService.setLoading(loadingInstanceID, false);
          }),
          catchError(async (error) => {
            if (!error.userCancelled) {
              await this.monetizationService.handleError({
                error,
                message: `Failed to purchase the product${this.monetizationService.getPurchasesErrorMessageDetails(
                  error
                )}.`,
                subHeader: 'Purchase Failed'
              });
            }
            return fromMonetizationActions.purchaseMobileProductFailure({ error });
          })
        );
      })
    )
  );

  purchaseWebProduct$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMonetizationActions.purchaseWebProduct),
        withLatestFrom(this.store.select(fromAppSettingsSelectors.getAppSettingsUserProfileData)),
        map((data) => ({
          monetizationPackage: data[0].monetizationPackage,
          redirectType: data[0].redirectType,
          user: data[1]
        })),
        filter(({ user }) => !isNil(user)),
        delay(500),
        switchMap(({ monetizationPackage, redirectType, user }) =>
          this.apiService
            .sendRequest({
              method: 'POST',
              requestOptions: {
                body: {
                  clientReferenceId: user.id,
                  container: containerName,
                  customerEmail: user.email,
                  mode: 'payment',
                  priceId: monetizationPackage.product.price.id,
                  uiCancelUrl: `${location.protocol}//${window.location.host}/#/payment/${redirectType}/redirect/${MonetizationPaymentRedirectTypes.Cancel}`,
                  uiSuccessUrl: `${location.protocol}//${window.location.host}/#/payment/${redirectType}/redirect/${MonetizationPaymentRedirectTypes.Success}`
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Checkout/Sessions`
            })
            .pipe(
              concatMap((stripeCheckoutSession: MonetizationStripeCheckoutSession) =>
                this.stripeService.redirectToCheckout({ sessionId: stripeCheckoutSession.id })
              ),
              catchError(async (error) => {
                await this.monetizationService.handleError({
                  error,
                  message: `Failed to perform purchase (${error.message}).`,
                  subHeader: 'Stripe Checkout'
                });
                return fromMonetizationActions.purchaseWebProductFailure({
                  error
                });
              })
            )
        )
      ),
    {
      dispatch: false
    }
  );

  loadStoreProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadStoreProducts),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map((data) => ({
        isMobilePlatform: data[1]
      })),
      map(({ isMobilePlatform }) =>
        isMobilePlatform
          ? fromMonetizationActions.loadMobileStoreProducts()
          : fromMonetizationActions.loadWebStoreProducts()
      )
    )
  );

  loadWebStoreProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadWebStoreProducts),
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromMonetizationSelectors.selectStoreProductIdentifiers),
        this.store.select(fromAppConfig.getInAppProducts)
      ),
      map((data) => ({
        inAppProducts: data[3],
        productIds: data[2],
        user: data[1]
      })),
      filter(({ user }) => !isNil(user?.email)),
      switchMap(({ user, productIds, inAppProducts }) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: {
                ids: productIds
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Products`
          })
          .pipe(
            switchMap((stripeProducts: MonetizationStripeProduct[]) => [
              new parseActions.GetRegisteredUser({
                email: user.email
              }),
              fromMonetizationActions.loadWebStoreProductsSuccess({ inAppProducts, stripeProducts })
            ]),
            catchError((error) => of(fromMonetizationActions.loadWebStoreProductsFailure({ error })))
          )
      )
    )
  );

  loadMobileStoreProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadMobileStoreProducts),
      withLatestFrom(this.store.select(fromAppSettings.getAppSettingsUserProfileData)),
      map((data) => ({
        user: data[1]
      })),
      filter(({ user }) => !isNil(user?.email)),
      switchMap(({ user }) => [
        new parseActions.GetRegisteredUser({
          email: user.email
        }),
        fromMonetizationActions.loadMobileStoreProductsSuccess()
      ])
    )
  );

  loadProductDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadProductDetails),
      withLatestFrom(this.platformDetectService.getIsMobileSubscription()),
      map(([action, isMobilePlatform]) =>
        isMobilePlatform
          ? fromMonetizationActions.loadMobileProductDetails({ productId: action.productId })
          : fromMonetizationActions.loadWebProductDetails({ productId: action.productId })
      )
    )
  );

  loadWebProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadWebProductDetails),
      withLatestFrom(this.store.select(fromMonetizationSelectors.selectStoreProductData)),
      switchMap(([action, storeProductsData]) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: {
                ids: [action.productId]
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/Stripe/Products`
          })
          .pipe(
            switchMap((monetizationStripeProducts: MonetizationStripeProduct[]) => {
              if (!monetizationStripeProducts.length) {
                throw new Error('Product not found');
              }

              return [
                fromMonetizationActions.loadWebProductDetailsSuccess({
                  monetizationStripeProduct: monetizationStripeProducts[0],
                  storeProductsData
                })
              ];
            }),
            catchError(async (error) => {
              await this.monetizationService.handleError({
                error,
                message: `Failed to load Stripe product details (${error.message}).`,
                subHeader: 'Stripe Product Information'
              });
              return fromMonetizationActions.loadWebProductDetailsFailure({ error });
            })
          )
      )
    )
  );

  loadBadgerBucks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMonetizationActions.loadBadgerBucks),
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getAppInfoParseUrlType)
      ),
      map((data) => ({
        parseUrlType: data[2],
        user: data[1]
      })),
      filter(({ user }) => !isNil(user?.email) && !isNil(user?.id)),
      switchMap(({ user, parseUrlType }) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${user.id}/Purchase/BADGER_BUCKS_1000`
          })
          .pipe(
            switchMap(() => [
              fromMonetizationActions.loadBadgerBucksSuccess(),
              new parseActions.GetRegisteredUser({
                email: user.email
              })
            ]),
            catchError(async (error) => {
              await this.monetizationService.handleError({
                error,
                message: `Failed to update Badger Bucks (${error.message}).`,
                subHeader: 'Badger Bucks Update'
              });
              return fromMonetizationActions.loadBadgerBucksFailure(error);
            })
          )
      )
    )
  );

  loadBadgerBucksSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromMonetizationActions.loadBadgerBucksSuccess),
        withLatestFrom(
          this.store.select(fromAppSettings.getAppSettingsUserProfileData),
          this.platformDetectService.getIsMobileSubscription()
        ),
        map((data) => ({
          isMobilePlatform: data[2],
          user: data[1]
        })),
        filter(({ user }) => !isNil(user?.id)),
        switchMap(({ isMobilePlatform, user }) =>
          from(this.monetizationService.updateMonetizationConfiguration(user.id, isMobilePlatform)).pipe(
            catchError(async (error) => {
              await this.monetizationService.handleError({
                error,
                message: `Failed to setup payments (${error.message}).`,
                subHeader: 'RevenueCat Initialization'
              });
              return error;
            })
          )
        )
      ),
    {
      dispatch: false
    }
  );

  getVirtualCurrencyPreview$ = createEffect(() =>
    this.actions$.pipe(ofType(fromMonetizationActions.loadVirtualCurrencyPreview)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettingsSelectors.getAppSettingsUserProfileData),
        this.store.select(fromMonetizationSelectors.selectInAppPurchasesFarmPurchase),
        this.store.select(fromAppConfig.getAppInfoParseUrlType)
      ),
      map((data) => ({
        parseUrlType: data[3],
        propertyIds: data[0].propertyIds,
        user: data[1],
        viewedFarm: data[2]
      })),
      filter(({ user }) => !isNil(user?.id)),
      switchMap(({ parseUrlType, propertyIds, user, viewedFarm }) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: {
                farm: {
                  id: viewedFarm.farmId,
                  propertyIds
                }
              },
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${user.id}/VirtualCurrencyPurchase/PHONES_AND_EMAILS/Preview`
          })
          .pipe(
            switchMap((response: VirtualCurrencyPreview) => [
              fromMonetizationActions.loadVirtualCurrencyPreviewSuccess({
                preview: response
              })
            ]),
            catchError(() => of(fromMonetizationActions.loadVirtualCurrencyPreviewFailure()))
          )
      )
    )
  );

  getVirtualCurrencyPurchase$ = createEffect(() =>
    this.actions$.pipe(ofType(fromMonetizationActions.loadVirtualCurrencyPurchase)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettingsSelectors.getAppSettingsUserProfileData),
        this.store.select(fromMonetizationSelectors.selectInAppPurchasesFarmPurchase),
        this.store.select(fromAppConfig.getAppInfoParseUrlType)
      ),
      map((data) => ({
        parseUrlType: data[3],
        propertyIds: data[0].propertyIds,
        user: data[1],
        viewedFarm: data[2]
      })),
      filter(({ user }) => !isNil(user?.id)),
      switchMap(({ parseUrlType, propertyIds, user, viewedFarm }) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: {
                farm: {
                  id: viewedFarm.farmId,
                  propertyIds
                }
              },
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${user.id}/VirtualCurrencyPurchase/PHONES_AND_EMAILS`
          })
          .pipe(
            switchMap((response: VirtualCurrencyPurchaseResponse) => [
              fromMonetizationActions.loadVirtualCurrencyPurchaseSuccess(response),
              new appParseAction.GetRegisteredUser({ email: user.email })
            ]),
            catchError(() => of(fromMonetizationActions.loadVirtualCurrencyPurchaseFailure()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private monetizationService: MonetizationService,
    private platformDetectService: PlatformDetectService,
    private purchases: Purchases,
    private store: Store,
    private stripeService: StripeService
  ) {}
}
