import { ApiConfig } from '@redshed/netsheet-calculator/lib/services/api.service';

export const ENV = {
  isDebugMode: true,
  production: false
};

export const appVersion = '6.2.0';
export const appBuildNumber = '587';
export const containerName: string = 'smartdirect';

export const BASE_URL = 'https://test.propertyforcemobile.com/v4test/TitleService.svc/Secure/';

export const PARSE_URL = {
  '': 'https://rs-parse-middleware-qa.herokuapp.com/', // default
  ort: 'https://rs-parse-middleware-ort-qa.herokuapp.com/',
  redshed: 'https://rs-parse-middleware-qa.herokuapp.com/'
};

export const PARSE_TOKEN = {
  key: 'RS-PARSE-MIDDLEWARE-SECURITY-KEY',
  value: '3BF7933E68CF1E655392DCDDE27C1'
};

export const SHOW_CONSOLE = true;

export const NET_SHEET_CONFIG: ApiConfig = {
  netsheet: {
    domain: 'netsheet-stage.smartdirectre.com',
    port: 3003,
    schema: 'https',
    version: 'v1'
  },
  report: {
    domain: 'stage-report-service.smartdirectre.com',
    schema: 'https',
    securityHeader: 'NETCALCS-SECURITY-KEY',
    securityKey: '1F32EE2E7AEC53A74B3B67F977A69',
    version: 'v2'
  }
};

export const REVENUE_CAT_PUBLIC_KEY = 'strp_wlwQucCHEysihcLDvFdVDIqBOlH';

export const STRIPE_PUBLIC_KEY =
  'pk_test_51Jx3BDHc5vpwpDJ3qOigkqR9bQ6ogQsn4M3qdNTWVS5NRMgwha9EokPqLDeoqCyE68JEgQmmUAyRA1QuBLALYKUc00bx3jJRMi';

export const ADMIN_PORTAL_CONFIG = {
  apiUrl: 'https://stage-admin.smartdirectre.com/api',
  appId: '84b04f56-6011-4164-9f14-a05a0e56d9fa',
  appSecret: '1ad0869080087b2b52b2727b2bd79f6f'
};

export const NETCALCS_REPORTS_CONFIG = {
  apiUrl: 'https://stage-report-service.smartdirectre.com/api',
  appId: '1F32EE2E7AEC53A74B3B67F977A69'
};
