import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import { Store } from '@ngrx/store';
import { defaultsDeep } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { InternalStorageData } from '../../../typings/app';

import * as fromAppConfigGeneralInfoActions from '../../../store/actions/app-config/general-info';

@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {
  private initialized = false;
  private storageInstance = new BehaviorSubject<Storage | null>(null);

  constructor(
    private storage: Storage,
    private store: Store
  ) {
    this.init();
  }

  init() {
    if (!this.initialized) {
      this.initialized = true;
      this.storage.create().then((storage) => {
        this.storageInstance.next(storage);
        storage.get('app').then((state: InternalStorageData | undefined) => {
          if (state && state.isAuthenticated) {
            this.store.dispatch(new fromAppConfigGeneralInfoActions.UpdateGeneralInfoFromInternalStorage(state));
          }
        });
      });
    }
  }

  getStorageDataAsObservable(key: string) {
    return this.storageInstance.pipe(
      filter((storage) => !!storage),
      switchMap((storage) => {
        return storage.get(key);
      })
    );
  }

  getStorageData(key: string) {
    return this.storage.get(key);
  }

  setStorageData(key: string, value: unknown) {
    return this.storage.set(key, value);
  }

  clear() {
    return this.storage.clear();
  }

  async updateInternalStorage(dataToUpdate: Partial<InternalStorageData>) {
    const storageData = await this.getStorageData('app');
    await this.setStorageData('app', defaultsDeep(dataToUpdate, storageData));
  }
}
