import { Injectable } from '@angular/core';

import { timer } from 'rxjs';

import { ADMIN_PORTAL_CONFIG, appVersion, containerName, ENV } from '../../../../environments/environment';
import { adminPortalEndpoints } from '../../../constants/admin-portal';
import { AuthData } from '../../../typings/app';
import { LogItem } from '../../../typings/logger';
import { MobileUserGetAttributes } from '../../../typings/mobile-user';

import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private maxFailAttempts = 10;
  private hostname = '';
  private repCode = '';
  private userId = '';
  private logsStack: LogItem[] = [];

  constructor(private apiService: ApiService) {}

  updateAuthData(authData: AuthData, user: MobileUserGetAttributes) {
    this.repCode = authData?.repCode || '';
    this.userId = user?.id || '';
  }

  init() {
    const environment = ENV.production ? 'production' : 'test';
    this.hostname = `smartdirect-mobile-${environment}`;

    timer(600000, 600000).subscribe(() => {
      if (!this.maxFailAttempts) {
        this.maxFailAttempts = 10;
      }
    });

    timer(1500, 1500).subscribe(async () => {
      if (this.logsStack.length && !!this.maxFailAttempts) {
        const existingLogs = [...this.logsStack];
        this.logsStack = [];

        try {
          await this.apiService.sendRequestPromise({
            method: 'POST',
            requestOptions: {
              body: existingLogs
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.logs}`
          });

          this.maxFailAttempts = 10;
        } catch (error) {
          this.maxFailAttempts--;

          this.logsStack = [...existingLogs, ...this.logsStack];
        }
      }
    });
  }

  logPapertrailError(error: Error) {
    let errorMessage = `[Container: ${containerName}]`;
    errorMessage = `${errorMessage}[Version: ${appVersion}]`;
    errorMessage = this.repCode ? `${errorMessage}[RepCode: ${this.repCode}]` : errorMessage;
    errorMessage = this.userId ? `${errorMessage}[UserId: ${this.userId}]` : errorMessage;
    errorMessage = `${errorMessage} ${error.message}`;
    if (error['stack']) {
      errorMessage += ` Stack: ${error['stack']}`;
    }

    this.logsStack.push({
      hostname: this.hostname,
      level: 'error',
      message: errorMessage
    });
  }
}
