import { Action } from '@ngrx/store';

import { MobileUserGetAttributes, MobileUserUpdateAttributes } from '../../../typings/mobile-user';
import { MobileUserFarmAlerts } from '../../../typings/mobile-user';
import { ProfileHistoryItem, PropertyProfileData, PropertyReport } from '../../../typings/property-profile';
import { CreateFarmData, FarmBody, FarmData, FarmPropertyAlert, PropertyData } from '../../../typings/walking-farm';

export enum ParseActionTypes {
  CreateFarm = '[Parse] Create Farm',
  CreateFarmFail = '[Parse] Create Farm Fail',
  CreateFarmSuccess = '[Parse] Create Farm Success',
  CreateUser = '[Parse] Create User',
  CreateUserFail = '[Parse] Create User Fail',
  CreateUserSuccess = '[Parse] Create User Success',
  DeleteFarmReport = '[Parse] Delete Farm Report',
  DeleteFarmReportFail = '[Parse] Delete Farm Report Fail',
  DeleteFarmReportSuccess = '[Parse] Delete Farm Report Success',
  DeletePropertyProfile = '[Parse] Delete Property Profile',
  DeletePropertyProfileFail = '[Parse] Delete Property Profile Fail',
  DeletePropertyProfileSuccess = '[Parse] Delete Property Profile Success',
  DeleteUserData = '[Parse] Delete User Data',
  DeleteUserDataFail = '[Parse] Delete User Data Fail',
  DeleteUserDataSuccess = '[Parse] Delete User Data Success',
  GetFarmAlertSettings = '[Parse] Get Farm Alert Settings',
  GetFarmAlertSettingsFail = '[Parse] Get Farm Alert Settings Fail',
  GetFarmAlertSettingsSuccess = '[Parse] Get Farm Alert Settings Success',
  GetFarmAlertsForFarm = '[Parse] Get Farm Alerts For a Farm',
  GetFarmAlertsForFarmFail = '[Parse] Get Farm Alerts For a Farm Fail',
  GetFarmAlertsForFarmSuccess = '[Parse] Get Farm Alerts For a Farm Success',
  GetFarmReport = '[Parse] Get Farm Report',
  GetFarmReportSuccess = '[Parse] Get Farm Report Success',
  GetProfileHistory = '[Parse] Get Profile History',
  GetProfileHistoryFail = '[Parse] Get Profile History Fail',
  GetProfileHistorySuccess = '[Parse] Get Profile History Success',
  GetPropertyProfile = '[Parse] Get Property Profile',
  GetPropertyProfileFail = '[Parse] Get Property Profile Fail',
  GetPropertyProfileSuccess = '[Parse] Get Property Profile Success',
  GetRegisteredUser = '[Parse] Get Registered User',
  GetRegisteredUserFail = '[Parse] Get Registered User Fail',
  GetRegisteredUserSuccess = '[Parse] Get Registered User Success',
  GetSavedFarmReports = '[Parse] Get Saved Farm Reports',
  GetSavedFarmReportsFail = '[Parse] Get Saved Farm Reports Fail',
  GetSavedFarmReportsSuccess = '[Parse] Get Saved Farm Reports Success',
  GetUser = '[Parse] Get User',
  GetWalkingFarm = '[Parse] Get Walking Farm',
  GetWalkingFarmFail = '[Parse] Get Walking Farm Fail',
  MarkFarmAlertAsRead = '[Parse] Mark Farm Alert As Read',
  MarkFarmAlertAsReadFail = '[Parse] Mark Farm Alert As Read Fail',
  MarkFarmAlertAsReadSuccess = '[Parse] Mark Farm Alert As Read Success',
  MarkMultipleFarmAlertsAsRead = '[Parse] Mark Multiple Farm Alerts As Read',
  MarkMultipleFarmAlertsAsReadFail = '[Parse] Mark Multiple Farm Alerts As Read Fail',
  MarkMultipleFarmAlertsAsReadSuccess = '[Parse] Mark Multiple Farm Alerts As Read Success',
  ReorderFarmProperties = '[Parse] Reorder Farm Properties',
  ReorderFarmPropertiesFail = '[Parse] Reorder Farm Properties Fail',
  ResetParseCallsState = '[Parse] Reset Parse Calls State',
  SavePropertyProfile = '[Parse] Save Property Profile',
  SavePropertyProfileFail = '[Parse] Save Property Profile Fail',
  SavePropertyProfileSuccess = '[Parse] Save Property Profile Success',
  SetWalkingFarmData = '[Parse] Set Walking Farm Data',
  ToggleFarmAlertSubscription = '[Parse] Toggle Farm Alert Subscription',
  ToggleFarmAlertSubscriptionFail = '[Parse] Toggle Farm Alert Subscription Fail',
  ToggleFarmAlertSubscriptionSuccess = '[Parse] Toggle Farm Alert Subscription Success',
  UpdateDeviceId = '[Parse] Update Device Id',
  UpdateDeviceIdSuccess = '[Parse] Update Device Id Success',
  UpdateFarmReportProperties = '[Parse] Update Farm Report Properties',
  UpdateFarmReportPropertiesFail = '[Parse] Update Farm Report Properties Fail',
  UpdateFarmReportPropertiesSuccess = '[Parse] Update Farm Report Properties Success',
  UpdateFirebaseToken = '[Parse] Update Firebase Token',
  UpdateFirebaseTokenSuccess = '[Parse] Update Firebase Token Success',
  UpdateUser = '[Parse] Update User',
  UpdateUserFail = '[Parse] Update User Fail',
  UpdateUserFarmAlertSettings = '[Parse] Update User Farm Alert Settings',
  UpdateUserFarmAlertSettingsFail = '[Parse] Update User Farm Alert Settings Fail',
  UpdateUserFarmAlertSettingsSuccess = '[Parse] Update User Farm Alert Settings Success',
  UpdateUserSuccess = '[Parse] Update User Success',
  UpdateUserToken = '[Parse] Update User Token',
  UpdateUserTokenFail = '[Parse] Update User Token Fail',
  UpdateUserTokenSuccess = '[Parse] Update User Token Success'
}

export class GetPropertyProfile implements Action {
  readonly type = ParseActionTypes.GetPropertyProfile;
  constructor(public payload: string) {}
}
export class GetPropertyProfileSuccess implements Action {
  readonly type = ParseActionTypes.GetPropertyProfileSuccess;
  constructor(public payload: { propertyReport: PropertyReport; providerId: string }) {}
}
export class GetPropertyProfileFail implements Action {
  readonly type = ParseActionTypes.GetPropertyProfileFail;
  constructor(public payload: Error) {}
}

export class SavePropertyProfile implements Action {
  readonly type = ParseActionTypes.SavePropertyProfile;
  constructor(public payload: { d: PropertyProfileData }) {}
}
export class SavePropertyProfileSuccess implements Action {
  readonly type = ParseActionTypes.SavePropertyProfileSuccess;
  constructor(public payload: { changes: Partial<ProfileHistoryItem>; id: string }) {}
}
export class SavePropertyProfileFail implements Action {
  readonly type = ParseActionTypes.SavePropertyProfileFail;
  constructor(public payload: Error) {}
}

export class GetProfileHistory implements Action {
  readonly type = ParseActionTypes.GetProfileHistory;
  constructor() {}
}
export class GetProfileHistorySuccess implements Action {
  readonly type = ParseActionTypes.GetProfileHistorySuccess;
  constructor(public payload: ProfileHistoryItem[]) {}
}
export class GetProfileHistoryFail implements Action {
  readonly type = ParseActionTypes.GetProfileHistoryFail;
  constructor(public payload: Error) {}
}

export class DeletePropertyProfile implements Action {
  readonly type = ParseActionTypes.DeletePropertyProfile;
  constructor(public payload: string) {}
}
export class DeletePropertyProfileSuccess implements Action {
  readonly type = ParseActionTypes.DeletePropertyProfileSuccess;
  constructor(public payload: string) {}
}
export class DeletePropertyProfileFail implements Action {
  readonly type = ParseActionTypes.DeletePropertyProfileFail;
  constructor(public payload: { error: Error; id: string }) {}
}

export class GetWalkingFarm implements Action {
  readonly type = ParseActionTypes.GetWalkingFarm;
  constructor(public payload: string) {}
}
export class SetWalkingFarmData implements Action {
  readonly type = ParseActionTypes.SetWalkingFarmData;
  constructor(public payload: FarmData) {}
}
export class GetWalkingFarmFail implements Action {
  readonly type = ParseActionTypes.GetWalkingFarmFail;
  constructor(public payload: Error) {}
}

export class GetSavedFarmReports implements Action {
  readonly type = ParseActionTypes.GetSavedFarmReports;
  constructor() {}
}
export class GetSavedFarmReportsSuccess implements Action {
  readonly type = ParseActionTypes.GetSavedFarmReportsSuccess;
  constructor(public payload: FarmData[]) {}
}
export class GetSavedFarmReportsFail implements Action {
  readonly type = ParseActionTypes.GetSavedFarmReportsFail;
  constructor(public payload: Error) {}
}

export class DeleteFarmReport implements Action {
  readonly type = ParseActionTypes.DeleteFarmReport;
  constructor(public payload: string) {}
}
export class DeleteFarmReportSuccess implements Action {
  readonly type = ParseActionTypes.DeleteFarmReportSuccess;
  constructor(public payload: string) {}
}
export class DeleteFarmReportFail implements Action {
  readonly type = ParseActionTypes.DeleteFarmReportFail;
  constructor(
    public payload: {
      error: Error;
      farmReportId: string;
    }
  ) {}
}

export class UpdateFarmReportProperties implements Action {
  readonly type = ParseActionTypes.UpdateFarmReportProperties;
  constructor(public payload: { farmId: string; properties: PropertyData[] }) {}
}
export class UpdateFarmReportPropertiesSuccess implements Action {
  readonly type = ParseActionTypes.UpdateFarmReportPropertiesSuccess;
  constructor() {}
}
export class UpdateFarmReportPropertiesFail implements Action {
  readonly type = ParseActionTypes.UpdateFarmReportPropertiesFail;
  constructor(public payload: Error) {}
}

export class GetFarmAlertsForFarm implements Action {
  readonly type = ParseActionTypes.GetFarmAlertsForFarm;
  constructor(public payload: string) {}
}
export class GetFarmAlertsForFarmSuccess implements Action {
  readonly type = ParseActionTypes.GetFarmAlertsForFarmSuccess;
  constructor(public payload: FarmPropertyAlert[]) {}
}
export class GetFarmAlertsForFarmFail implements Action {
  readonly type = ParseActionTypes.GetFarmAlertsForFarmFail;
  constructor(public payload: Error) {}
}

export class MarkFarmAlertAsRead implements Action {
  readonly type = ParseActionTypes.MarkFarmAlertAsRead;
  constructor(public payload: string) {}
}
export class MarkFarmAlertAsReadSuccess implements Action {
  readonly type = ParseActionTypes.MarkFarmAlertAsReadSuccess;
  constructor(public payload: string) {}
}
export class MarkFarmAlertAsReadFail implements Action {
  readonly type = ParseActionTypes.MarkFarmAlertAsReadFail;
  constructor(public payload: Error) {}
}

export class MarkMultipleFarmAlertsAsRead implements Action {
  readonly type = ParseActionTypes.MarkMultipleFarmAlertsAsRead;
  constructor(public payload: string[]) {}
}
export class MarkMultipleFarmAlertsAsReadSuccess implements Action {
  readonly type = ParseActionTypes.MarkMultipleFarmAlertsAsReadSuccess;
  constructor(public payload: string[]) {}
}
export class MarkMultipleFarmAlertsAsReadFail implements Action {
  readonly type = ParseActionTypes.MarkMultipleFarmAlertsAsReadFail;
  constructor(public payload: Error) {}
}

export class ToggleFarmAlertSubscription implements Action {
  readonly type = ParseActionTypes.ToggleFarmAlertSubscription;
  constructor(public payload: { farmId: string; hasFarmAlerts: boolean }) {}
}
export class ToggleFarmAlertSubscriptionSuccess implements Action {
  readonly type = ParseActionTypes.ToggleFarmAlertSubscriptionSuccess;
  constructor(public payload: FarmData) {}
}
export class ToggleFarmAlertSubscriptionFail implements Action {
  readonly type = ParseActionTypes.ToggleFarmAlertSubscriptionFail;
  constructor(public payload: Error) {}
}

export class GetUser implements Action {
  readonly type = ParseActionTypes.GetUser;
  constructor() {}
}

export class GetRegisteredUser implements Action {
  readonly type = ParseActionTypes.GetRegisteredUser;
  constructor(public payload: { email: string }) {}
}
export class GetRegisteredUserSuccess implements Action {
  readonly type = ParseActionTypes.GetRegisteredUserSuccess;
  constructor(public payload: Partial<MobileUserGetAttributes>) {}
}
export class GetRegisteredUserFail implements Action {
  readonly type = ParseActionTypes.GetRegisteredUserFail;
  constructor(public payload: string) {}
}

export class CreateUser implements Action {
  readonly type = ParseActionTypes.CreateUser;
  constructor() {}
}
export class CreateUserSuccess implements Action {
  readonly type = ParseActionTypes.CreateUserSuccess;
  constructor(public payload: { id: string }) {}
}
export class CreateUserFail implements Action {
  readonly type = ParseActionTypes.CreateUserFail;
  constructor(public payload: Error) {}
}

export class UpdateUser implements Action {
  readonly type = ParseActionTypes.UpdateUser;
  constructor(public payload: { company: string; firstName: string; lastName: string; phone: string }) {}
}
export class UpdateUserSuccess implements Action {
  readonly type = ParseActionTypes.UpdateUserSuccess;
  constructor(public payload: Partial<MobileUserGetAttributes>) {}
}
export class UpdateUserFail implements Action {
  readonly type = ParseActionTypes.UpdateUserFail;
  constructor(public payload: Error) {}
}

export class UpdateFirebaseToken implements Action {
  readonly type = ParseActionTypes.UpdateFirebaseToken;
  constructor(public payload: { firebaseToken: string }) {}
}
export class UpdateFirebaseTokenSuccess implements Action {
  readonly type = ParseActionTypes.UpdateFirebaseTokenSuccess;
  constructor() {}
}

export class UpdateUserToken implements Action {
  readonly type = ParseActionTypes.UpdateUserToken;
  constructor(public payload: string) {}
}
export class UpdateUserTokenSuccess implements Action {
  readonly type = ParseActionTypes.UpdateUserTokenSuccess;
  constructor() {}
}
export class UpdateUserTokenFail implements Action {
  readonly type = ParseActionTypes.UpdateUserTokenFail;
  constructor(public payload: Error) {}
}

export class ResetParseCallsState implements Action {
  readonly type = ParseActionTypes.ResetParseCallsState;
  constructor() {}
}

export class GetFarmAlertSettings implements Action {
  readonly type = ParseActionTypes.GetFarmAlertSettings;

  constructor() {}
}
export class GetFarmAlertSettingsSuccess implements Action {
  readonly type = ParseActionTypes.GetFarmAlertSettingsSuccess;

  constructor(public payload: MobileUserFarmAlerts) {}
}
export class GetFarmAlertSettingsFail implements Action {
  readonly type = ParseActionTypes.GetFarmAlertSettingsFail;

  constructor(public payload: Error) {}
}

export class UpdateUserFarmAlertSettings implements Action {
  readonly type = ParseActionTypes.UpdateUserFarmAlertSettings;

  constructor(public payload: MobileUserFarmAlerts) {}
}
export class UpdateUserFarmAlertSettingsSuccess implements Action {
  readonly type = ParseActionTypes.UpdateUserFarmAlertSettingsSuccess;

  constructor(public payload: MobileUserUpdateAttributes) {}
}
export class UpdateUserFarmAlertSettingsFail implements Action {
  readonly type = ParseActionTypes.UpdateUserFarmAlertSettingsFail;

  constructor(public payload: Error) {}
}

export class DeleteUserData implements Action {
  readonly type = ParseActionTypes.DeleteUserData;

  constructor() {}
}
export class DeleteUserDataSuccess implements Action {
  readonly type = ParseActionTypes.DeleteUserDataSuccess;

  constructor() {}
}
export class DeleteUserDataFail implements Action {
  readonly type = ParseActionTypes.DeleteUserDataFail;

  constructor() {}
}

export class GetFarmReport implements Action {
  readonly type = ParseActionTypes.GetFarmReport;
  constructor(public payload: CreateFarmData) {}
}
export class GetFarmReportSuccess implements Action {
  readonly type = ParseActionTypes.GetFarmReportSuccess;
  constructor() {}
}

export class CreateFarm implements Action {
  readonly type = ParseActionTypes.CreateFarm;

  constructor(
    public payload: {
      requestBody: FarmBody;
    }
  ) {}
}
export class CreateFarmSuccess implements Action {
  readonly type = ParseActionTypes.CreateFarmSuccess;

  constructor(public payload: { changes: Partial<FarmData>; id: string }) {}
}
export class CreateFarmFail implements Action {
  readonly type = ParseActionTypes.CreateFarmFail;

  constructor(public payload: Error) {}
}

export class UpdateDeviceId implements Action {
  readonly type = ParseActionTypes.UpdateDeviceId;

  constructor() {}
}
export class UpdateDeviceIdSuccess implements Action {
  readonly type = ParseActionTypes.UpdateDeviceIdSuccess;

  constructor() {}
}

export class ReorderFarmProperties implements Action {
  readonly type = ParseActionTypes.ReorderFarmProperties;

  constructor(public payload: { farmId: string; properties: PropertyData[] }) {}
}
export class ReorderFarmPropertiesFail implements Action {
  readonly type = ParseActionTypes.ReorderFarmPropertiesFail;

  constructor(public payload: Error) {}
}

export type ParseActions =
  | GetPropertyProfile
  | GetPropertyProfileSuccess
  | GetPropertyProfileFail
  | SavePropertyProfile
  | SavePropertyProfileSuccess
  | SavePropertyProfileFail
  | GetProfileHistory
  | GetProfileHistorySuccess
  | GetProfileHistoryFail
  | DeletePropertyProfile
  | DeletePropertyProfileSuccess
  | DeletePropertyProfileFail
  | GetWalkingFarm
  | SetWalkingFarmData
  | GetWalkingFarmFail
  | GetSavedFarmReports
  | GetSavedFarmReportsSuccess
  | GetSavedFarmReportsFail
  | GetFarmReport
  | GetFarmReportSuccess
  | DeleteFarmReport
  | DeleteFarmReportSuccess
  | DeleteFarmReportFail
  | UpdateFarmReportProperties
  | UpdateFarmReportPropertiesSuccess
  | UpdateFarmReportPropertiesFail
  | GetFarmAlertsForFarm
  | GetFarmAlertsForFarmSuccess
  | GetFarmAlertsForFarmFail
  | MarkFarmAlertAsRead
  | MarkFarmAlertAsReadSuccess
  | MarkFarmAlertAsReadFail
  | MarkMultipleFarmAlertsAsRead
  | MarkMultipleFarmAlertsAsReadSuccess
  | MarkMultipleFarmAlertsAsReadFail
  | ToggleFarmAlertSubscription
  | ToggleFarmAlertSubscriptionSuccess
  | ToggleFarmAlertSubscriptionFail
  | GetRegisteredUser
  | GetRegisteredUserSuccess
  | GetRegisteredUserFail
  | GetUser
  | CreateUser
  | CreateUserSuccess
  | CreateUserFail
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFail
  | UpdateFirebaseToken
  | UpdateFirebaseTokenSuccess
  | UpdateUserToken
  | UpdateUserTokenSuccess
  | UpdateUserTokenFail
  | ResetParseCallsState
  | UpdateUserFarmAlertSettings
  | UpdateUserFarmAlertSettingsSuccess
  | UpdateUserFarmAlertSettingsFail
  | GetFarmAlertSettings
  | GetFarmAlertSettingsSuccess
  | GetFarmAlertSettingsFail
  | DeleteUserData
  | DeleteUserDataFail
  | DeleteUserDataSuccess
  | CreateFarm
  | CreateFarmSuccess
  | CreateFarmFail
  | UpdateDeviceId
  | UpdateDeviceIdSuccess
  | ReorderFarmProperties
  | ReorderFarmPropertiesFail;
