import { NgModule } from '@angular/core';
import { NgModel, ReactiveFormsModule } from '@angular/forms';

import { FormErrorMessageDirective } from './form-error-message.directive';
import { FormPhoneNumberDirective } from './form-phone-number.directive';
import { RateAmountDirective } from './rate-amount.directive';
import { TextAreaLogsDirective } from './text-area-logs.directive';

import { StoreProductImageDirective } from './store-product-image.directive';
import { StoreProductLabelDirective } from './store-product-label.directive';

export const directives = [
  RateAmountDirective,
  TextAreaLogsDirective,
  FormErrorMessageDirective,
  FormPhoneNumberDirective,
  StoreProductImageDirective,
  StoreProductLabelDirective
];

@NgModule({
  declarations: [...directives],
  exports: [...directives],
  imports: [ReactiveFormsModule],
  providers: [NgModel]
})
export class DirectivesModule {}
