import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRoles } from '../../enums/user';

import * as fromAppConfigSelectors from '../../store/selectors/app-config';

import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store
  ) {}

  /**
   * Main guard method that handles the canActivate functionality.
   */
  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(fromAppConfigSelectors.getIsAuthenticated).pipe(
      map((isAuthenticated) => {
        let userRole = UserRoles.Guest;
        let urlTree = this.router.createUrlTree(['welcome']);

        if (isAuthenticated) {
          userRole = UserRoles.LoggedUser;
          urlTree = this.router.createUrlTree(['home']);
        }

        this.authService.setUserRole(userRole);

        return route?.data?.role === userRole || urlTree;
      })
    );
  }
}
