import { cloneDeep, findIndex, forEach, isArray } from 'lodash-es';

import {
  FarmData,
  FarmTurnOver,
  PolygonFarmCoordinates,
  PropertyData,
  RadiusFarmCoordinates
} from '../../../typings/walking-farm';

import * as parseActions from '../../actions/parse/parse-implementation';
import * as fromFarmActions from '../../actions/walking-farm/farm';

export interface State {
  credits: number;
  data: FarmData | null;
  error: Error;

  farmCoordinates: PolygonFarmCoordinates | null;
  hasNextItem: boolean;

  hasPreviousItem: boolean;
  pageIndex: number;
  pending: boolean;
  rawFarmCoordinates: PolygonFarmCoordinates | RadiusFarmCoordinates | null;

  succeeded: boolean;
  totalItems: number;
  turnOver: FarmTurnOver;
}

export const initialState: State = {
  credits: 0,
  data: null,
  error: null,

  farmCoordinates: null,
  hasNextItem: false,

  hasPreviousItem: false,
  pageIndex: 0,
  pending: false,
  rawFarmCoordinates: null,

  succeeded: false,
  totalItems: 0,
  turnOver: {
    count: 0,
    message: [],
    percent: ''
  }
};

export function reducer(state = initialState, action: fromFarmActions.FarmActions | parseActions.ParseActions) {
  switch (action.type) {
    case parseActions.ParseActionTypes.GetWalkingFarm: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case fromFarmActions.FarmActionTypes.UpdateViewedFarmProperties: {
      let farmProperties: PropertyData[];

      if (action.payload.isSelectiveUpdate) {
        farmProperties = cloneDeep(state.data.properties);

        forEach(action.payload.properties, (updatedProperty) => {
          const updatedPropertyIndex = findIndex(
            state.data.properties,
            (property) => property.A000_PropertyNumber === updatedProperty.A000_PropertyNumber
          );

          if (updatedPropertyIndex > -1) {
            farmProperties[updatedPropertyIndex] = updatedProperty;
          }
        });
      } else {
        farmProperties = cloneDeep(action.payload.properties);
      }

      return {
        ...state,
        data: {
          ...state.data,
          properties: farmProperties,
          propertyCount: !isNaN(action.payload.propertyCount) ? action.payload.propertyCount : state.data.propertyCount
        },
        error: null,
        pending: false,
        succeeded: false
      };
    }

    case parseActions.ParseActionTypes.ToggleFarmAlertSubscriptionSuccess: {
      return {
        ...state,
        data: {
          ...state.data,
          hasFarmAlerts: action.payload.hasFarmAlerts
        },
        error: null,
        pending: false,
        succeeded: false
      };
    }

    case fromFarmActions.FarmActionTypes.IncludeFarmPropertiesMarkers: {
      return {
        ...state,
        data: {
          ...state.data,
          markers: action.payload
        },
        error: null,
        pending: false,
        succeeded: false
      };
    }

    case fromFarmActions.FarmActionTypes.GetFarmCreditsSuccess: {
      return {
        ...state,
        credits: action.payload,
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case fromFarmActions.FarmActionTypes.GetFarmCountSuccess: {
      return {
        ...state,
        farmCoordinates: action.payload.farmCoordinates,
        rawFarmCoordinates: action.payload.rawFarmCoordinates,
        turnOver: action.payload.turnOver
      };
    }

    case fromFarmActions.FarmActionTypes.ResetViewedFarm: {
      return {
        ...initialState,
        error: null,
        pending: false,
        succeeded: false
      };
    }

    case parseActions.ParseActionTypes.GetWalkingFarmSuccess: {
      const properties = isArray(action.payload.properties) ? action.payload.properties : [];

      return {
        ...state,
        data: {
          ...state.data,
          propertyCount: properties.length,
          ...action.payload,
          properties: properties
        },
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case parseActions.ParseActionTypes.GetWalkingFarmFail: {
      return {
        ...state,
        error: action.payload,
        hasNextItem: false,
        hasPreviousItem: false,
        pageIndex: 0,
        pending: false,
        succeeded: false,
        totalItems: 0
      };
    }

    default: {
      return state;
    }
  }
}

export const getFarmData = (state: State) => state.data;
export const getFarmDataHasFarmAlerts = (state: State) => !!state.data?.hasFarmAlerts;
export const getFarmDataFarmId = (state: State) => state.data?.id;
export const getFarmDataFarmName = (state: State) => state.data?.name;
export const getFarmIAPData = (state: State) => state.data?.inAppPurchases;
export const getFarmCredits = (state: State) => state.credits;
export const getFarmTurnOver = (state: State) => state?.turnOver;
export const getRawFarmCoordinates = (state: State) => state?.rawFarmCoordinates;
export const getFarmCoordinates = (state: State) => state?.farmCoordinates;
