import { Action } from '@ngrx/store';

import { InternalStorageData } from '../../../typings/app';
import { LoginData } from '../../../typings/auth';
import { MobileUserGetAttributes } from '../../../typings/mobile-user';

export enum UserProfileActionTypes {
  AuthenticateUser = '[User Profile] Authenticate User',
  AuthenticateUserFail = '[User Profile] Authenticate User Error',
  AuthenticateUserSuccess = '[User Profile] Authenticate User Success',
  ChangeExpiredPassword = '[User Profile] Change Expired Password',
  LogoutUser = '[User Profile] Logout User',
  LogoutUserSuccess = '[User Profile] Logged out User Successfully',
  ResetPassword = '[User Profile] Reset Password',
  ResetPasswordFail = '[User Profile] Reset Password Fail',
  ResetPasswordSuccess = '[User Profile] Reset Password Success',
  ResetUserProfile = '[User Profile] Reset User Profile',
  ResetUserProfileErrors = '[User Profile] Reset User Profile Errors',
  SetAppUserData = '[User Profile] Set User Profile Data',
  UpdateSalesInfoFromInternalStorage = '[User Profile] Update Sales Info From Internal Storage',
  UpdateUserFromInternalStorage = '[User Profile] Update User From Internal Storage',
  VerifyUser = '[User Profile] Verify User',
  VerifyUserFail = '[User Profile] Verify User Fail',
  VerifyUserSuccess = '[User Profile] Verify User Success'
}

export class ResetPassword implements Action {
  readonly type = UserProfileActionTypes.ResetPassword;

  constructor(public payload: { newPassword: string; oldPassword: string; username: string }) {}
}

export class ResetPasswordSuccess implements Action {
  readonly type = UserProfileActionTypes.ResetPasswordSuccess;

  constructor(
    public payload: {
      newPassword: string;
      username: string;
    }
  ) {}
}

export class ResetPasswordFail implements Action {
  readonly type = UserProfileActionTypes.ResetPasswordFail;

  constructor(public payload: Error) {}
}

export class SetAppUserData implements Action {
  readonly type = UserProfileActionTypes.SetAppUserData;

  constructor(public payload: Partial<MobileUserGetAttributes>) {}
}

export class VerifyUser implements Action {
  readonly type = UserProfileActionTypes.VerifyUser;
  constructor(public payload: LoginData) {}
}
export class VerifyUserSuccess implements Action {
  readonly type = UserProfileActionTypes.VerifyUserSuccess;

  constructor(public payload: { password: string; username: string }) {}
}

export class VerifyUserFail implements Action {
  readonly type = UserProfileActionTypes.VerifyUserFail;

  constructor(public payload: Error) {}
}

export class AuthenticateUser implements Action {
  readonly type = UserProfileActionTypes.AuthenticateUser;

  constructor(public payload: { password: string; username: string }) {}
}
export class AuthenticateUserSuccess implements Action {
  readonly type = UserProfileActionTypes.AuthenticateUserSuccess;

  constructor(public payload: { password: string; username: string }) {}
}
export class AuthenticateUserFail implements Action {
  readonly type = UserProfileActionTypes.AuthenticateUserFail;

  constructor(public payload: Error) {}
}
export class ResetUserProfile implements Action {
  readonly type = UserProfileActionTypes.ResetUserProfile;

  constructor() {}
}
export class ResetUserProfileErrors implements Action {
  readonly type = UserProfileActionTypes.ResetUserProfileErrors;

  constructor() {}
}

export class UpdateSalesInfoFromInternalStorage implements Action {
  readonly type = UserProfileActionTypes.UpdateSalesInfoFromInternalStorage;

  constructor(public payload: Partial<InternalStorageData>) {}
}

export class UpdateUserFromInternalStorage implements Action {
  readonly type = UserProfileActionTypes.UpdateUserFromInternalStorage;

  constructor(public payload: Partial<InternalStorageData>) {}
}

export class ChangeExpiredPassword implements Action {
  readonly type = UserProfileActionTypes.ChangeExpiredPassword;

  constructor(public payload: boolean) {}
}

export class LogoutUser implements Action {
  readonly type = UserProfileActionTypes.LogoutUser;

  constructor() {}
}
export class LogoutUserSuccess implements Action {
  readonly type = UserProfileActionTypes.LogoutUserSuccess;

  constructor() {}
}

export type UserProfileAction =
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFail
  | SetAppUserData
  | VerifyUser
  | VerifyUserSuccess
  | VerifyUserFail
  | AuthenticateUser
  | AuthenticateUserSuccess
  | AuthenticateUserFail
  | ResetUserProfile
  | ResetUserProfileErrors
  | UpdateSalesInfoFromInternalStorage
  | UpdateUserFromInternalStorage
  | ChangeExpiredPassword
  | LogoutUser
  | LogoutUserSuccess;
