import { Injectable } from '@angular/core';

import { PropertyData } from '../../../typings/walking-farm';

@Injectable({
  providedIn: 'root'
})
export class ZipService {
  constructor() {}

  parseQueryValue(property: Partial<PropertyData>) {
    let result = '';

    if (`${property.A007_ZipCode}`.length === 10) {
      result = property.A007_ZipCode.replace(/^(\d{5}).*/, '$1');
    } else if (`${property.A008_Zip4}`.length === 10) {
      result = property.A008_Zip4.replace(/^(\d{5}).*/, '$1');
    } else if (property.A007_ZipCode) {
      result = property.A007_ZipCode;
    }

    return result;
  }

  formatDisplayValue(property: Partial<PropertyData>) {
    let result = '';

    if (`${property.A007_ZipCode}`.length === 10) {
      result = property.A007_ZipCode;
    } else if (`${property.A008_Zip4}`.length === 10) {
      result = property.A008_Zip4;
    } else if (property.A007_ZipCode) {
      result = property.A008_Zip4 ? `${property.A007_ZipCode}-${property.A008_Zip4}` : property.A007_ZipCode;
    }

    return result;
  }
}
