<div class="tw-relative tw-my-3 tw-w-full">
  <ion-searchbar
    #searchBar
    color="light"
    showClearButton="always"
    class="ion-no-padding lg:tw-h-auto lg:tw-px-0"
    [ngClass]="{
      'show-cancel should-show-clear': searchKey,
      'filters-menu-active': showDropDownMenu,
      'smd-mobile-ion-searchbar': isMobilePlatform
    }"
    [(ngModel)]="searchKey"
    (ionChange)="onChange($event)"
    (ionClear)="onClear($event)"
    (ionBlur)="onBlur()"
    (ionFocus)="onFocus()"
    [placeholder]="placeholder"
  ></ion-searchbar>

  <ng-container *ngIf="showMoreFiltersBtn && hasOptions">
    <ion-button
      class="smd-search-filters-btn tw-absolute tw-right-3 tw-top-0 tw-h-10 tw-w-7 tw-h-full lg:tw-h-9"
      fill="clear"
      [ngClass]="{
        hidden: searchKey,
        visible: !searchKey
      }"
      (click)="toggleSearchFilters($event)"
    >
      <ion-icon class="ion-md-icon-more-search-options tw-h-5 tw-text-xl tw-text-gray-600"></ion-icon>
    </ion-button>

    <smd-dropdown-menu
      *ngIf="showDropDownMenu"
      class="tw-block tw-border-t tw-border-gray-400 lg:tw-border lg:tw-border-gray-200"
      [dataToDisplay]="searchFilters"
      (clickDropDownItem)="triggerDropdownAction($event)"
      (smdClickOutside)="hideDropdown()"
    ></smd-dropdown-menu>
  </ng-container>
</div>
