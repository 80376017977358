import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';

import { LOG_LEVEL, Purchases } from '@awesome-cordova-plugins/purchases/ngx';
import { Store } from '@ngrx/store';
import { PurchasesError } from 'cordova-plugin-purchases';
import { compact, has, isFunction, isNil } from 'lodash-es';
import { combineLatest, from, of } from 'rxjs';
import { catchError, filter, map, switchMap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { ENV, REVENUE_CAT_PUBLIC_KEY } from '../../../../environments/environment';
import { MonetizationAlertTypes, MonetizationPrimarySubscriptionPlans } from '../../../enums/monetization';
import { MonetizationPaywallAlertButtonOptions, MonetizationPaywallAlertOptions } from '../../../typings/monetization';

import * as fromMonetizationActions from '../../../store/actions/monetization.actions';
import { monetizationFeatureKey } from '../../../store/reducers/monetization.reducer';
import * as fromAppSettings from '../../../store/selectors/app-settings';
import * as fromMonetizationSelectors from '../../../store/selectors/monetization.selectors';

import { LoadingService } from '../loading/loading.service';
import { LoggerService } from '../logger/logger.service';
import { PlatformDetectService } from '../platform-detect/platform-detect.service';
import { SecureStorageService } from '../secure-storage/secure-storage.service';

interface HandleMonetizationErrorOptions {
  error?: Error;
  header?: string;
  message?: string;
  subHeader?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MonetizationService {
  private isSetupDone = false;
  private isMobileSetupDone = false;

  private refreshPurchaserInfoInterval: ReturnType<typeof setInterval> | undefined;

  private primarySubscriptionAccessAlias: string | null = null;
  private isPrimarySubscriptionActive = false;
  private subscriptionPlatformProductIdentifier: string | null = null;

  static freeAllowedFarms = 2;

  constructor(
    private alertController: AlertController,
    private loadingService: LoadingService,
    private loggerService: LoggerService,
    private navController: NavController,
    private platformDetectService: PlatformDetectService,
    private purchases: Purchases,
    private secureStorageService: SecureStorageService,
    private store: Store
  ) {
    this.store
      .select(fromMonetizationSelectors.selectPrimarySubscriptionPlanAccessAlias)
      .subscribe((primarySubscriptionPlanAccessAlias) => {
        this.primarySubscriptionAccessAlias = primarySubscriptionPlanAccessAlias;
      });

    this.store
      .select(fromMonetizationSelectors.selectPrimarySubscriptionIsActive)
      .subscribe((isPrimarySubscriptionPlanActive) => {
        this.isPrimarySubscriptionActive = isPrimarySubscriptionPlanActive;
      });

    this.store
      .select(fromMonetizationSelectors.selectPrimarySubscriptionPlatformProductIdentifier)
      .subscribe((platformProductIdentifier) => {
        this.subscriptionPlatformProductIdentifier = platformProductIdentifier;
      });
  }

  init() {
    if (!this.isSetupDone) {
      this.isSetupDone = true;

      this.secureStorageService.getStorageData('app').then((storageData) => {
        if (storageData) {
          this.store.dispatch(
            fromMonetizationActions.restoreMonetization({
              state: storageData[monetizationFeatureKey]
            })
          );
        }

        this.store
          .select(fromMonetizationSelectors.selectMonetization)
          .pipe(
            throttleTime(300, undefined, { leading: true, trailing: true }),
            switchMap((monetizationState) =>
              this.secureStorageService.updateInternalStorage({
                [monetizationFeatureKey]: JSON.parse(JSON.stringify(monetizationState))
              })
            )
          )
          .subscribe();
      });

      combineLatest([
        this.store.select(fromMonetizationSelectors.selectIsMonetizationEnabled),
        this.store.select(fromAppSettings.getAppSettingsUserProfileData)
      ])
        .pipe(
          throttleTime(300, undefined, { leading: true, trailing: true }),
          filter(([isMonetizationEnabled, user]) => isMonetizationEnabled && !isNil(user)),
          withLatestFrom(
            this.platformDetectService.getIsMobileSubscription(),
            this.store.select(fromMonetizationSelectors.selectIsRevenueCatUserLoggedIn)
          ),
          map(([[_isMonetizationEnabled, user], isMobilePlatform, isRevenueCatUserLoggedIn]) => ({
            isMobilePlatform,
            isRevenueCatUserLoggedIn,
            userId: user.id
          })),
          switchMap(({ isMobilePlatform, userId, isRevenueCatUserLoggedIn }) =>
            from(this.updateMonetizationConfiguration(userId, isMobilePlatform, isRevenueCatUserLoggedIn)).pipe(
              catchError((error) => {
                this.handleError({
                  error,
                  message: `Failed to setup payments (${error.message}).`,
                  subHeader: 'RevenueCat Initialization'
                });
                return of(error);
              })
            )
          )
        )
        .subscribe();

      combineLatest([
        this.store.select(fromMonetizationSelectors.selectIsMonetizationEnabled),
        this.store.select(fromMonetizationSelectors.selectPrimarySubscriptionPackagePlatformProductIdentifier)
      ])
        .pipe(
          throttleTime(300, undefined, { leading: true, trailing: true }),
          filter((results) => results[0] && !!results[1])
        )
        .subscribe(() => {
          this.store.dispatch(fromMonetizationActions.loadPrimarySubscriptionPackagePlatformProductDetails());
        });
    }
  }

  async updateMonetizationConfiguration(userId: string, isMobilePlatform: boolean, isRevenueCatUserLoggedIn: boolean) {
    const loadingInstanceID = uuidV4();
    this.loadingService.setLoading(loadingInstanceID, true);
    if (isMobilePlatform) {
      if (!this.isMobileSetupDone) {
        this.isMobileSetupDone = true;
        this.purchases.setLogLevel(ENV.production ? LOG_LEVEL.ERROR : LOG_LEVEL.DEBUG);
        this.purchases.configureWith({
          apiKey: REVENUE_CAT_PUBLIC_KEY
        });
        this.purchases.onCustomerInfoUpdated().subscribe((customerInfo) => {
          this.store.dispatch(fromMonetizationActions.loadMobilePurchaserInfoSuccess({ customerInfo }));
        });
      }
      try {
        if (isRevenueCatUserLoggedIn) {
          await this.purchases.logOut();
        }
        await this.purchases.logIn(userId);
        this.store.dispatch(
          fromMonetizationActions.setIsRevenueCatUserLoggedIn({
            isRevenueCatUserLoggedIn: true
          })
        );
      } catch (error) {
        this.handleError({
          error,
          message: `Failed to authenticate (${error.message}).`,
          subHeader: 'RevenueCat Initialization'
        });
      }
    } else if (!this.refreshPurchaserInfoInterval) {
      this.refreshPurchaserInfoInterval = setInterval(() => {
        this.store.dispatch(fromMonetizationActions.loadPurchaserInfo());
      }, 3600000);
    }

    this.store.dispatch(fromMonetizationActions.loadPurchaserInfo());
    this.store.dispatch(fromMonetizationActions.loadOfferingsInfo());
    this.loadingService.setLoading(loadingInstanceID, false);
  }

  async handleError(options: HandleMonetizationErrorOptions) {
    if (options.error) {
      this.loggerService.logPapertrailError(options.error);
    }
    if (options.message) {
      const alert = await this.alertController.create({
        buttons: [
          {
            role: 'cancel',
            text: 'Continue'
          }
        ],
        header: options.header ?? 'Payments System Error',
        message: options.message,
        subHeader: options.subHeader ?? null
      });
      await alert.present();
    }
  }

  accessFeature(
    paymentPlans: string[],
    options?: {
      failureCallback?: Function;
      paywallPage?: {
        justLogged: boolean;
      };
      successCallback?: Function;
    }
  ) {
    let check: boolean;

    if (paymentPlans.includes(this.primarySubscriptionAccessAlias)) {
      check = this.isPrimarySubscriptionActive;
    } else {
      check = true;
    }

    if (options) {
      if (check && options.successCallback) {
        options.successCallback();
      } else if (!check && options.failureCallback) {
        options.failureCallback();
      } else if (!check) {
        this.navController.navigateRoot(['/', 'payment', 'details', this.subscriptionPlatformProductIdentifier]);
      }
    }

    return check;
  }

  validateWalkingFarmsByAmount(
    dataLength: number,
    options: {
      failureCallback?: Function;
      successCallback?: Function;
    }
  ) {
    if (dataLength >= MonetizationService.freeAllowedFarms) {
      this.accessFeature([MonetizationPrimarySubscriptionPlans.PlanC], {
        failureCallback: options?.failureCallback,
        successCallback: options?.successCallback
      });
    } else if (options?.successCallback) {
      options.successCallback();
    }
  }

  async displayMonetizationAlert(
    alertOptions: MonetizationPaywallAlertOptions,
    buttonOptions?: MonetizationPaywallAlertButtonOptions
  ) {
    try {
      const alert = await this.alertController.create({
        buttons: [
          {
            role: 'cancel',
            text: 'Continue',
            ...buttonOptions?.cancelButton,
            handler: () => {
              const customHandler = buttonOptions?.cancelButton?.handler;
              if (isFunction(customHandler)) {
                customHandler();
              }
            }
          },
          {
            handler: () => {
              this.navController.navigateRoot(['/', 'payment', 'details', this.subscriptionPlatformProductIdentifier]);
            },
            text: 'Subscribe'
          }
        ],
        ...(alertOptions || {})
      });

      await alert.present();
    } catch (error) {
      const alert = await this.alertController.create({
        buttons: [
          {
            role: 'cancel',
            text: 'OK'
          }
        ],
        message: `Failed to display the payment alert - ${error.message}`
      });

      await alert.present();
    }
  }

  getSubscriptionAlertMessage(alertType: string, options?: { savedFarmReportsLength: number }) {
    let message = '';

    switch (alertType) {
      case MonetizationAlertTypes.UnsubscribedOpenSavedFarmList:
        message =
          `Without a subscription, you may only retain ${MonetizationService.freeAllowedFarms} saved farm reports.` +
          ` You may continue to access all your farm reports with a valid subscription.`;
        break;

      case MonetizationAlertTypes.UnsubscribedCreateNewFarm:
        message =
          `Without a subscription, you are only allowed to maintain ${MonetizationService.freeAllowedFarms}` +
          ` saved farm reports and you currently have ${options?.savedFarmReportsLength || ''}.` +
          ` You must either delete excess farm reports or purchase a subscription.`;
        break;

      case MonetizationAlertTypes.UnsubscribedOpenSavedFarm:
        message =
          `Without a subscription, you are only allowed to maintain ${MonetizationService.freeAllowedFarms}` +
          ` saved farm reports and you currently have ${options?.savedFarmReportsLength || ''}.` +
          ` You must either delete excess farm reports or purchase a subscription.`;
        break;

      default:
        break;
    }

    return message;
  }

  getPurchasesErrorMessageDetails(error: Error | PurchasesError) {
    let messageParts = [];
    let message = '';
    if (has(error, 'readableErrorCode')) {
      messageParts.push(`[${error['readableErrorCode']}]`);
    } else if (has(error, 'code')) {
      messageParts.push(`[${error['code']}]`);
    }
    if (error.message) {
      messageParts.push(error.message);
    }
    messageParts = compact(messageParts);
    if (messageParts.length) {
      message = ` (${messageParts.join(' ')})`;
    }
    return message;
  }
}
