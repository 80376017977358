import { filter, map, max, min } from 'lodash-es';

import { DateComparable } from '../typings/property-profile';
import { PropertyData } from '../typings/walking-farm';

export function sortByDate(a: DateComparable, b: DateComparable) {
  return a.reportDate < b.reportDate ? 1 : -1;
}

export function getAvgValueOfMinMaxWithinAnYear(
  collection: PropertyData[],
  propertyName: string,
  startDate?: string,
  propertyDateName?: string
) {
  const value1 = getMaxMinValueWithinAnYear(collection, propertyName, 'min', startDate, propertyDateName);
  const value2 = getMaxMinValueWithinAnYear(collection, propertyName, 'max', startDate, propertyDateName);
  return !!value1 && value1 > 0 && !!value2 && value2 > 0 ? Math.round((Number(value1) + Number(value2)) / 2) : 'N/A';
}
export function getMaxMinValueWithinAnYear(
  collection: PropertyData[],
  propertyName: string,
  maxOrMin: string,
  startDate?: string,
  propertyDateName?: string
) {
  const dataWithinAnYear = filter(
    collection,
    (i) => getTimeDifferenceToCurrentTime(i[`${propertyDateName}`], 'months', startDate) <= 12
  );
  const dataWithValues = filter(
    map(dataWithinAnYear, (i) => Number(i[`${propertyName}`])),
    (item) => !!item
  );
  return maxOrMin === 'max' ? max(dataWithValues) : min(dataWithValues);
}

export function getAvgValueOfMinMax(collection: PropertyData[], propertyName: string, _round = false) {
  const value1 = getMaxMinValue(collection, propertyName, 'min');
  const value2 = getMaxMinValue(collection, propertyName, 'max');
  return !!value1 && value1 > 0 && !!value2 && value2 > 0 ? Math.round((Number(value1) + Number(value2)) / 2) : 'N/A';
}

export function getMaxMinValue(collection: PropertyData[], propertyName: string, maxOrMin: string) {
  const dataWithValues = filter(
    map(collection, (i) => i[`${propertyName}`]),
    (item) => !!item
  );
  return maxOrMin === 'max' ? max(dataWithValues) : min(dataWithValues);
}

export function getTimeDifferenceToCurrentTime(date: string, differenceAs: string = 'hours', startDate?: string) {
  const timeNow = startDate ? new Date(startDate).getTime() : new Date().getTime();
  const timeBefore = new Date(date).getTime();

  let result: number;

  switch (differenceAs) {
    case 'years':
      result = Math.round(new Date(timeNow).getFullYear() - new Date(timeBefore).getFullYear());
      break;

    case 'months':
      result = Math.round(Math.abs(timeNow - timeBefore) / (1000 * 60 * 60 * 24 * 7 * 4));
      break;

    case 'weeks':
      result = Math.round(Math.abs(timeNow - timeBefore) / (1000 * 60 * 60 * 24 * 7));
      break;

    case 'days':
      result = Math.round(Math.abs(timeNow - timeBefore) / (1000 * 60 * 60 * 24));
      break;

    default:
      result = Math.round(Math.abs(timeNow - timeBefore) / (1000 * 60 * 60));
      break;
  }

  return result;
}
