import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { has, replace } from 'lodash-es';
import { Observable, of, throwError } from 'rxjs';
import { pluck, switchMap, take } from 'rxjs/operators';

import * as fromAppConfigGeneralInfoActions from '../../store/actions/app-config/general-info';

import { PropertyForceCustomErrorHandler } from '../../providers/pf-interceptor/pf-interceptor.constants';

@Injectable({
  providedIn: 'root'
})
export class KeyRefreshInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private actions: Actions
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let handledRequest: Observable<HttpEvent<unknown>>;

    if (request.headers.has(PropertyForceCustomErrorHandler)) {
      handledRequest = next.handle(request).pipe(
        switchMap((event) => {
          let handledEvent = of(event);
          if (event instanceof HttpResponse) {
            const header = request.headers.get(PropertyForceCustomErrorHandler);
            const status = has(event, ['body', 'd', 'Status']) ? event.body.d.Status : '';

            if (
              (status.match(/\[code13_IK]/) ||
                ['Invalid Access Key', 'Invalid accessKey', 'Invalid Key'].indexOf(status) !== -1) &&
              ['PP-KEY-REFRESH', 'FARM-KEY-REFRESH'].indexOf(header) !== -1
            ) {
              this.refreshTheKey(header);
              handledEvent = this.remakeRequestWithNewKey(request, event, next, status);
            }
          }
          return handledEvent;
        })
      );
    } else {
      handledRequest = next.handle(request);
    }
    return handledRequest;
  }

  refreshTheKey(header: string) {
    switch (header) {
      case 'PP-KEY-REFRESH':
        this.store.dispatch(new fromAppConfigGeneralInfoActions.GetPropertyProfileKey({ withKeyRefresh: true }));
        break;
      case 'FARM-KEY-REFRESH':
        this.store.dispatch(new fromAppConfigGeneralInfoActions.GetPropertyFarmKey({ withKeyRefresh: true }));
        break;
    }
  }

  remakeRequestWithNewKey(request: HttpRequest<unknown>, event: HttpEvent<unknown>, next: HttpHandler, status: string) {
    return this.actions.pipe(ofType('KEY_REFRESHED')).pipe(
      take(1),
      pluck('payload'),
      switchMap((key: string) => {
        let handledEvent = of(event);
        if (key) {
          handledEvent = next.handle(
            request.clone({
              setParams: {
                key
              }
            })
          );
        } else {
          return throwError(replace(status, 'ERROR:', ''));
        }

        return handledEvent;
      })
    );
  }
}
