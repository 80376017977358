import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { PfLocation } from '../typings/app';
import { GeocodeItem } from '../typings/map';

import { PfHelperService } from './pf-helper-service.service';

@Injectable()
export class GeocodeService {
  private geocoder = new google.maps.Geocoder();
  private addresses: GeocodeItem[];
  private delay = 100;
  private nextAddress = -1;
  private m: PfLocation[] = [];
  private results = new Subject<PfLocation[]>();

  constructor(private pfHelper: PfHelperService) {}

  searchByLongLat(loc: { lat: number | null; lng: number | null }) {
    return new Observable((subscriber) => {
      this.geocoder.geocode({ location: loc }, async (results, status) => {
        let result: google.maps.GeocoderResult | null = null;
        if (status === google.maps.GeocoderStatus.OK) {
          result = results[0];
        } else {
          await this.pfHelper.showSystemError('Cannot find address.');
        }
        subscriber.next(result);
      });
    });
  }

  find(addresses: GeocodeItem[]) {
    this.addresses = addresses;
    this.search();
    return this;
  }

  markers() {
    return this.results.asObservable();
  }

  reset() {
    this.m = [];
    this.nextAddress = -1;
  }

  private getAddress(item: GeocodeItem, next) {
    const $this = this;

    if (item == undefined) {
      next();
      return;
    }

    this.geocoder.geocode(
      { address: item.address, componentRestrictions: { country: 'USA' } },
      async (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const marker = {
            icon: {
              scaledSize: {
                height: 0,
                width: 0
              },
              url: ''
            },
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          };

          if (item.icon) {
            marker.icon = {
              scaledSize: {
                height: 45,
                width: 27
              },
              url: `/assets/svg/map-markers/${item.icon.url}`
            };
          }
          $this.m.push(marker);
        } else {
          if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
            $this.nextAddress--;
            $this.delay++;
          } else {
            $this.reset();
            await $this.pfHelper.showSystemError('Cannot find address.');
            return;
          }
        }
        next();
      }
    );
  }

  private search() {
    if (this.nextAddress < this.addresses.length) {
      setTimeout(() => this.getAddress(this.addresses[this.nextAddress], this.search.bind(this)), this.delay);
      this.nextAddress++;
    } else if (this.m.length === this.addresses.length) {
      this.results.next(this.m);
    }
  }
}
