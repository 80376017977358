<ion-card
  *ngIf="!isMobileKeyboardVisible"
  class="tw-m-0 tw-rounded-b-none tw-rounded-t-xl lg:tw-rounded-none tw-flex tw-flex-col tw-h-full lg:tw-h-auto"
  [ngClass]="{ 'lg:tw-mt-3': isIPad }"
  (swipe)="swipeEvent($event)"
>
  <ion-card-header class="ion-no-padding tw-max-h-16">
    <ion-grid class="ion-no-padding tw-max-w-full md:tw-py-1">
      <ion-row *ngIf="isMobileView && expand" class="tw-pt-1">
        <ion-col size="2" offset="5" class="tw-h-1 tw-rounded-2xl tw-bg-white tw-p-0 tw-opacity-80"></ion-col>
      </ion-row>

      <ion-row *ngIf="cardInfo">
        <ion-col class="ion-no-padding tw-px-2">
          <ion-item lines="none">
            <ion-avatar slot="start">
              <img [src]="agentPhotoUrl" class="tw-h-full" alt="Agent photo" />
            </ion-avatar>

            <ion-text class="tw-max-h-11 tw-text-sm">
              {{ cardInfo.firstName }} {{ cardInfo.lastName }}, {{ cardInfo.company }}
            </ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-header>

  <ion-card-content
    *ngIf="!isMobileView || (expand && isMobileView)"
    class="ion-no-padding tw-overflow-auto md:tw-px-4 tw-flex-auto tw-mb-4 lg:tw-mb-0 tw-flex tw-flex-col"
  >
    <ng-container *ngIf="cardInfo">
      <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-gap-6">
        <div *ngIf="cardInfo.email || cardInfo.phone" class="tw-flex-1">
          <ion-item lines="none" class="ion-no-padding tw-my-2 md:tw-my-0">
            <ion-grid class="ion-no-padding tw-max-w-full tw-px-4 lg:tw-px-0">
              <ion-row>
                <ion-col class="ion-no-padding">
                  <h3 class="tw-border-b tw-border-white tw-pb-1 tw-opacity-40">CONTACT ME</h3>
                </ion-col>
              </ion-row>

              <ion-row *ngIf="cardInfo.email" class="tw-mt-2">
                <ion-col class="ion-no-padding tw-flex">
                  <ion-button
                    *ngIf="isMobilePlatform"
                    fill="clear"
                    class="tw-flex tw-max-w-full"
                    (click)="onMailTo(cardInfo.email)"
                  >
                    <ion-icon slot="start" class="ion-md-icon-email"></ion-icon>
                    <ion-text class="tw-flex-1 tw-truncate">{{ cardInfo.email }}</ion-text>
                  </ion-button>

                  <a
                    #desktopMailTo
                    class="tw-flex tw-max-w-full tw-cursor-pointer"
                    *ngIf="!isMobilePlatform"
                    (click)="onMailTo(cardInfo.email)"
                  >
                    <ion-icon color="light" class="ion-md-icon-email tw-m-auto"></ion-icon>
                    <ion-text class="tw-ml-1 tw-flex-1 tw-truncate">{{ cardInfo.email }}</ion-text>
                  </a>
                </ion-col>
              </ion-row>

              <ng-container *ngIf="cardInfo.phone">
                <ion-row class="tw-px-2.5 lg:tw-p-0">
                  <ion-col class="ion-no-padding tw-grow-0">
                    <ion-button fill="clear" [href]="'tel:' + cardInfo.phone" class="ion-no-padding">
                      <ion-icon slot="start" class="ion-md-icon-phone"></ion-icon>
                      <ion-text>{{ cardInfo.phone | smdNumberFormatter: 'phone' }}</ion-text>
                    </ion-button>
                  </ion-col>

                  <ion-col class="ion-no-padding tw-ml-4 tw-flex tw-grow-0 tw-items-center">
                    <ion-text>or</ion-text>
                  </ion-col>

                  <ion-col class="ion-no-padding tw-grow-0">
                    <ion-button fill="clear" [href]="'sms:' + cardInfo.phone" class="ion-no-padding tw-ml-4">
                      <ion-icon slot="start" class="ion-md-icon-sms"></ion-icon>
                      <ion-text>SMS</ion-text>
                    </ion-button>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="isMobileView" class="tw-mt-2">
                  <ion-col class="ion-no-padding">
                    <ion-button fill="clear" [disabled]="isContactSaveLoading" (click)="onContactAdd()">
                      <ion-icon slot="start" class="ion-md-icon-add-contact"></ion-icon>
                      <ion-text>ADD TO CONTACTS</ion-text>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ng-container>
            </ion-grid>
          </ion-item>
        </div>

        <div *ngIf="cardInfo.customerServiceNumber" class="tw-flex-1">
          <ion-item lines="none" class="ion-no-padding tw-my-2 md:tw-my-0">
            <ion-grid class="ion-no-padding tw-max-w-full tw-px-4 lg:tw-px-0">
              <ion-row>
                <ion-col class="ion-no-padding">
                  <h3 class="tw-border-b tw-border-white tw-pb-1 tw-opacity-40">CUSTOMER SERVICE</h3>
                </ion-col>
              </ion-row>

              <ion-row class="tw-mt-2 tw-px-2.5 lg:tw-p-0">
                <ion-col class="ion-no-padding">
                  <ion-button fill="clear" [href]="'tel:' + cardInfo.customerServiceNumber" class="ion-no-padding">
                    <ion-icon slot="start" class="ion-md-icon-phone"></ion-icon>
                    <ion-text>{{ cardInfo.customerServiceNumber }}</ion-text>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </div>
      </div>

      <div class="lg:tw-my-2 tw-px-4 lg:tw-px-0 tw-flex-auto">
        <img
          class="tw-max-h-72 tw-m-auto lg:tw-max-h-56"
          *ngIf="cardInfo.contactCardImageUrl"
          [src]="cardInfo.contactCardImageUrl"
          alt="Contact card image"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="!isMobileView">
      <ion-item lines="none" class="ion-no-padding">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col class="ion-no-padding tw-text-center">
              <ion-text>&copy; {{ copyright }}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ng-container>
  </ion-card-content>
</ion-card>
