import { combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromParseCreateFarmReport from '../../reducers/parse/create-farm-report';
import * as fromParse from '../../reducers/parse/parse-implementation';

export interface ParseState {
  createFarmReport: fromParseCreateFarmReport.State;
  parse: fromParse.State;
}

export interface State {
  appParse: ParseState;
}

export const reducer = combineReducers({
  createFarmReport: fromParseCreateFarmReport.reducer,
  parse: fromParse.reducer
});

export const getAppParseState = createFeatureSelector<ParseState>('appParse');

export const getParseState = createSelector(getAppParseState, (state) => state.parse);

export const getParseCallsError = createSelector(getParseState, fromParse.getError);

export const getParseCreateFarmReportState = createSelector(getAppParseState, (state) => state.createFarmReport);

export const getCreateFarmSucceeded = createSelector(
  getParseCreateFarmReportState,
  fromParseCreateFarmReport.getCreateFarmSucceeded
);
export const getCreateFarmError = createSelector(
  getParseCreateFarmReportState,
  fromParseCreateFarmReport.getCreateFarmError
);
export const getCreateFarmPending = createSelector(
  getParseCreateFarmReportState,
  fromParseCreateFarmReport.getCreateFarmPending
);
