import { Component, NgZone, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { NavTabsItem } from '../../../../typings/layout';

import { GenericComponent } from '../../generic/generic.component';

import * as fromAppConfig from '../../../../store/selectors/app-config';

import { ContainerService } from '../../../services/container/container.service';
import { LayoutService } from '../../../services/layout/layout.service';

@Component({
  selector: 'smd-desktop-tabs',
  templateUrl: './desktop-tabs.component.html'
})
export class DesktopTabsComponent extends GenericComponent implements OnInit {
  private tabsChangeDisabled = true;

  tabs: NavTabsItem[] = [];
  logoUrl = '';
  brandName = '';

  constructor(
    private ngZone: NgZone,
    private store: Store<fromAppConfig.State>,
    private layoutService: LayoutService,
    private containerService: ContainerService
  ) {
    super();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'tabsDataSubscription',
      this.layoutService.getNavTabsData().subscribe((tabsData) => {
        this.ngZone.run(() => {
          this.tabs = tabsData;
        });
      })
    );

    this.addUniqueSubscription(
      'containerSettingsSubscription',
      this.containerService
        .getContainerSettings()
        .pipe(filter((containerSettings) => !!containerSettings))
        .subscribe((containerSettings) => {
          this.ngZone.run(() => {
            this.brandName = containerSettings.brandName;
          });
        })
    );

    this.addUniqueSubscription(
      'logoUrlSubscription',
      this.store
        .select(fromAppConfig.getGeneralInfoAppInfoLogoUrl)
        .pipe(
          filter((logoUrl) => this.logoUrl !== logoUrl),
          take(1)
        )
        .subscribe((logoUrl) => {
          this.ngZone.run(() => {
            this.logoUrl = logoUrl;
          });
        })
    );
  }

  async handleTabNavigation(route: string[]) {
    await this.layoutService.handleTabNavigation(route);
  }

  onTabsChange() {
    if (!this.tabsChangeDisabled) {
      this.layoutService.handleTabsChange();
    } else {
      this.tabsChangeDisabled = false;
    }
  }

  async goHome() {
    await this.layoutService.goHome();
  }

  trackByActiveTab(index: number, tabItem: NavTabsItem) {
    return tabItem.route;
  }
}
