import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { has } from 'lodash-es';

import { loaderIgnoreKey } from '../constants/loading';
import { REP_DATA_RESPONSE_PROVIDER_ID_LPS } from '../../constants/main.constants';
import { BASE_URL } from '../../environments/environment';

import { PropertyForceCustomErrorHandler } from './pf-interceptor/pf-interceptor.constants';

@Injectable({
  providedIn: 'root'
})
export class ApiProvider {
  constructor(private http: HttpClient) {}

  getData(endPoint: string, options?, apiCallType?: string, providerId?: string, ignoreLoader?: boolean) {
    let headers = new HttpHeaders();

    if (ignoreLoader) {
      headers = headers.append(loaderIgnoreKey, 'true');
    }

    if (!options.key && providerId !== REP_DATA_RESPONSE_PROVIDER_ID_LPS) {
      headers = headers.append(PropertyForceCustomErrorHandler, '*');
    } else {
      headers = headers.append(PropertyForceCustomErrorHandler, apiCallType);
    }

    let params = new HttpParams();
    for (const i in options) {
      if (has(options, i)) {
        params = params.append(i, options[i]);
      }
    }

    return this.http.get(`${BASE_URL}${endPoint}`, { headers, params });
  }

  getKey(endPoint: string, options?, apiCallType?: string, ignoreLoader?: boolean) {
    let headers = new HttpHeaders();
    headers = headers.append(PropertyForceCustomErrorHandler, apiCallType);

    if (ignoreLoader) {
      headers = headers.append(loaderIgnoreKey, 'true');
    }

    let pfParams = new HttpParams();
    for (const i in options) {
      if (has(options, i)) {
        pfParams = pfParams.append(i, options[i]);
      }
    }

    return this.http.get(BASE_URL + endPoint, { headers, params: pfParams });
  }
}
