import { HttpErrorResponse } from '@angular/common/http';

import * as appParseActions from '../../actions/parse/parse-implementation';
import * as fromFarmActions from '../../actions/walking-farm/farm';

export interface State {
  error: HttpErrorResponse;
  pending: boolean;
  succeeded: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
  succeeded: false
};

export function reducer(state = initialState, action: appParseActions.ParseActions | fromFarmActions.FarmActions) {
  switch (action.type) {
    case appParseActions.ParseActionTypes.CreateFarm: {
      return {
        ...state,
        error: null,
        pending: true,
        succeeded: false
      };
    }

    case appParseActions.ParseActionTypes.CreateFarmSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
        succeeded: true
      };
    }

    case appParseActions.ParseActionTypes.CreateFarmFail: {
      return {
        ...state,
        error: action.payload,
        pending: false,
        succeeded: false
      };
    }

    case appParseActions.ParseActionTypes.ResetParseCallsState: {
      return initialState;
    }

    case fromFarmActions.FarmActionTypes.ResetViewedFarm: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getCreateFarmSucceeded = (state: State) => state.succeeded;
export const getCreateFarmError = (state: State) => state.error;
export const getCreateFarmPending = (state: State) => state.pending;
