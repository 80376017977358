<ion-modal [isOpen]="isModalOpen" (willDismiss)="onFarmNameEditModalDismiss()">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="onFarmNameEditModalDismiss()">Cancel</ion-button>
        </ion-buttons>
        <ion-title class="tw-text-center">Edit farm name</ion-title>
        <ion-buttons slot="end">
          <ion-button type="primary" (click)="onSubmit()" [disabled]="!form.valid || errorMessage"> Save </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <form
        class="tw-px-4"
        [formGroup]="form"
        (keyup.enter)="form.valid && onSubmit()"
        (ngSubmit)="form.valid && onSubmit()"
      >
        <ion-item lines="inset" class="ion-no-padding tw-mt-4">
          <ion-label position="stacked">
            <ion-text class="tw-text-lg">{{ farmNameFieldItem.label }}</ion-text>
            <ion-text color="danger" class="tw-text-lg" *ngIf="farmNameFieldItem.required">*</ion-text>
          </ion-label>

          <ion-input
            [type]="farmNameFieldItem.type"
            [formControlName]="farmNameFieldItem.name"
            (ionChange)="onFieldValueChange()"
          ></ion-input>
        </ion-item>

        <ng-container [smdFormErrorMessage]="farmNameFieldItem"></ng-container>

        <ion-text *ngIf="errorMessage" color="danger" class="tw-ml-0.5 tw-mt-1.5 tw-block tw-text-xs">
          {{ errorMessage }}
        </ion-text>
      </form>
    </ion-content>
  </ng-template>
</ion-modal>
