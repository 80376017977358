import { Injectable } from '@angular/core';

import moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {
  formatDate(value: string | number, formatRule?: string) {
    let result = value?.toString() || '';
    if (result) {
      result = moment(value, formatRule).format('MM/DD/YYYY');
    }

    return result;
  }

  formatLocaleDateTime(value: string | number, formatRule?: string) {
    let result = value?.toString() || '';

    if (result) {
      result = moment(value, formatRule).format('MM/DD/YYYY [AT] hh:mm A');
    }

    return result;
  }
}
