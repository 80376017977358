import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { ReportSummaryTabType } from '../../../enums/walking-farm';
import { RightButton } from '../../../typings/header';
import { RightClickNavigation } from '../../../typings/navigation';

import { GenericComponent } from '../generic/generic.component';

import * as fromAppConfig from '../../../store/selectors/app-config';

@Component({
  selector: 'smd-header-content',
  templateUrl: 'header-content.component.html'
})
export class HeaderContentComponent extends GenericComponent implements OnInit {
  private isAuthenticated = false;

  selectedTab: string;

  @Input() hasBackNavigation = false;
  @Input() backNavigationText = 'Go Back';
  @Input() hasIcon = false;
  @Input() closeIcon = false;

  @Input() goBackToPage: string;
  @Input() title: string;
  @Input() hasTitleClass: boolean;
  @Input() logoImg: string;
  @Input() subTitle: string;
  @Input() rightIcons: RightClickNavigation[];
  @Input() rightButtons: RightButton[];
  @Output() rightButtonClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() leftButtonClick: EventEmitter<PointerEvent | MouseEvent> = new EventEmitter<PointerEvent | MouseEvent>();

  get hasCloseIcon() {
    const hasCloseIcon = this.closeIcon || false;
    this.hasIcon = hasCloseIcon;
    return hasCloseIcon;
  }

  constructor(
    private store: Store,
    private navController: NavController,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'isAuthenticatedSubscription',
      this.store
        .select(fromAppConfig.getIsAuthenticated)
        .pipe(filter((isAuthenticated) => isAuthenticated !== null))
        .subscribe((isAuthenticated) => {
          this.isAuthenticated = isAuthenticated;
        })
    );
  }

  triggerLeftButton(event) {
    if (['/home/walking-farm/report-summary', '/home/walking-farm/alerts'].includes(this.router.url)) {
      this.navController.navigateBack(['/', 'home', 'walking-farm'], {
        state: {
          activeTab: 'savedReports'
        }
      });
    } else if (
      ['/home/walking-farm/property-details', '/home/walking-farm/property-alerts'].includes(this.router.url)
    ) {
      this.navController.navigateBack(['/', 'home', 'walking-farm', 'report-summary'], {
        state: {
          activeTab: ReportSummaryTabType.ListView
        }
      });
    } else if (this.goBackToPage) {
      this.handleGoBackToPageScenario();
    } else {
      this.navController.back();
    }

    this.leftButtonClick.emit(event);
  }

  triggerRightButton(componentName: string) {
    this.rightButtonClick.emit(componentName);
  }

  private handleGoBackToPageScenario() {
    const urlPaths = this.goBackToPage.split('/');
    if (urlPaths[0] === '') {
      urlPaths[0] = '/';
    } else {
      urlPaths.unshift('/');
    }

    if (urlPaths[urlPaths.length - 1] === 'walking-farm') {
      this.navController.navigateBack(urlPaths, {
        state: {
          activeTab: 'savedReports',
          isAuthenticated: this.isAuthenticated
        }
      });
    } else {
      this.navController.navigateBack(urlPaths);
    }
  }
}
