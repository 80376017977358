import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { isArray, isBoolean, isNil } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { STANDARD_PIN_SVG } from '../../../constants/map.constants';
import { ADMIN_PORTAL_CONFIG } from '../../../environments/environment';
import { adminPortalEndpoints } from '../../constants/admin-portal';
import {
  MobileUserCreateAttributes,
  MobileUserCreateResponseAttributes,
  MobileUserGetAttributes,
  MobileUserUpdateAttributes
} from '../../typings/mobile-user';

import { ApiProvider } from '../../providers/api.service';
import { MapUtils } from '../../providers/map-utils/map-utils.service';
import { PfHelperService } from '../../providers/pf-helper-service.service';
import { ApiService } from '../../common/services/api/api.service';
import { AuthService } from '../../common/services/auth/auth.service';
import { DeviceService } from '../../common/services/device/device.service';
import { PlatformDetectService } from '../../common/services/platform-detect/platform-detect.service';
import { SecureStorageService } from '../../common/services/secure-storage/secure-storage.service';

import * as appConfigGeneralInfoAction from '../actions/app-config/general-info';
import * as fromMonetizationActions from '../actions/monetization.actions';
import * as parseAction from '../actions/parse/parse-implementation';
import * as appParseAction from '../actions/parse/parse-implementation';
import * as propertyDataAction from '../actions/property-data/property-profile';
import * as walkingFarmActions from '../actions/walking-farm/farm';
import * as walkingFarmAction from '../actions/walking-farm/farm';
import * as fastFarmActions from '../actions/walking-farm/fast-farm';
import * as fromAppConfig from '../selectors/app-config';
import * as fromAppSettings from '../selectors/app-settings';
import * as fromWalkingFarm from '../selectors/walking-farm';
import * as walkingFarmSelectors from '../selectors/walking-farm';

@Injectable()
export class ParseEffects {
  getPropertyProfileFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetPropertyProfile>(parseAction.ParseActionTypes.GetPropertyProfile)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      switchMap(([action, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.propertyProfiles}/${action.payload}`
          })
          .pipe(
            switchMap((result) => {
              const dispatchActions: Action[] = [];

              if (result.reportContent.d) {
                dispatchActions.push(new parseAction.GetPropertyProfileSuccess(result));
                dispatchActions.push(new fastFarmActions.GetFastFarmData(result.reportContent.d));
              } else {
                dispatchActions.push(new propertyDataAction.GetPropertyProfileDataSuccess(result.reportContent));
                dispatchActions.push(new fastFarmActions.GetFastFarmData(result.reportContent));
              }
              return dispatchActions;
            }),
            catchError((error) => of(new parseAction.GetPropertyProfileFail(error)))
          )
      )
    )
  );

  getFastFarmData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fastFarmActions.GetFastFarmData>(fastFarmActions.FastFarmActionTypes.GetFastFarmData),
      switchMap((action) => {
        const result = action.payload;
        const address = `${result.SiteAddress} ${result.SiteUnitType} ${result.SiteUnit}, ${result.SiteCity} ${result.SiteState} ${result.SiteZip}`;

        return this.mapUtils
          .getMarker(address, {
            scaledSize: {
              height: 45,
              width: 27
            },
            url: STANDARD_PIN_SVG.red
          })
          .pipe(
            filter((location) => !!location?.latitude && !!location?.longitude),
            switchMap((location) => {
              return of(new fastFarmActions.GetMapMarker(location));
            }),
            catchError((error) => {
              this.pfHelperService.showSystemError(error);
              return of(error);
            })
          );
      })
    )
  );

  savePropertyProfileInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.SavePropertyProfile>(parseAction.ParseActionTypes.SavePropertyProfile)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey)
      ),
      filter(([_action, user]) => !isNil(user?.email)),
      map(([action, user, parseUrlType, appName]) => ({
        appName,
        email: user.email,
        parseUrlType,
        propertyProfileData: action.payload.d
      })),
      switchMap(({ propertyProfileData, parseUrlType, appName, email }) => {
        const requestBody = {
          address: `${propertyProfileData.SiteAddress} ${propertyProfileData.SiteUnitType} ${propertyProfileData.SiteUnit}, ${propertyProfileData.SiteCity}, ${propertyProfileData.SiteState} ${propertyProfileData.SiteZip}`,
          appName,
          displayCity: propertyProfileData.SiteCity,
          displayOwnerNames: `${propertyProfileData.PrimaryOwnerName}`,
          displayStateCode: propertyProfileData.SiteState,
          displayStreetAddress: `${propertyProfileData.SiteAddress} ${propertyProfileData.SiteUnitType} ${propertyProfileData.SiteUnit}`,
          email: email.toLocaleLowerCase(),
          reportContent: {
            d: propertyProfileData
          },
          reportVersion: 1
        };

        return this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: requestBody,
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.propertyProfiles}`
          })
          .pipe(
            map(
              (result) =>
                new parseAction.SavePropertyProfileSuccess({
                  changes: {
                    address: requestBody.address,
                    displayCity: requestBody.displayCity,
                    displayOwnerNames: requestBody.displayOwnerNames,
                    displayStateCode: requestBody.displayStateCode,
                    displayStreetAddress: requestBody.displayStreetAddress,
                    id: result.id,
                    reportDate: new Date(Date.now()).toISOString()
                  },
                  id: result.id
                })
            ),
            catchError((error) => of(new parseAction.SavePropertyProfileFail(error)))
          );
      })
    )
  );

  getProfileHistoryFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetProfileHistory>(parseAction.ParseActionTypes.GetProfileHistory)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey)
      ),
      filter(([_action, user]) => !isNil(user?.email)),
      switchMap(([_action, user, parseUrlType, appName]) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: {
                appName,
                email: user.email,
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.propertyProfiles}/History`
          })
          .pipe(
            map((result) => new parseAction.GetProfileHistorySuccess(result)),
            catchError((error) => of(new parseAction.GetProfileHistoryFail(error)))
          )
      )
    )
  );

  deletePropertyProfileFromParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.DeletePropertyProfile>(parseAction.ParseActionTypes.DeletePropertyProfile))
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
        map(([action, parseUrlType]) => [action.payload, parseUrlType]),
        switchMap(([propertyProfileId, parseUrlType]) =>
          this.apiService
            .sendRequest({
              method: 'DELETE',
              requestOptions: {
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.propertyProfiles}/${propertyProfileId}`
            })
            .pipe(
              map(() => new parseAction.DeletePropertyProfileSuccess(propertyProfileId)),
              catchError((error) =>
                of(
                  new parseAction.DeletePropertyProfileFail({
                    error,
                    id: propertyProfileId
                  })
                )
              )
            )
        )
      )
  );

  getFarmReportFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetWalkingFarm>(parseAction.ParseActionTypes.GetWalkingFarm)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      switchMap(([action, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${action.payload}`
          })
          .pipe(
            switchMap((result) => [
              new parseAction.GetWalkingFarmSuccess({ ...result, id: action.payload }),
              new walkingFarmActions.UpdateSavedFarm({
                changes: {
                  properties: isArray(result.properties) ? result.properties : []
                },
                id: action.payload
              }),
              new walkingFarmActions.LoadFarm({
                ...result,
                id: action.payload
              }),
              fromMonetizationActions.setInAppPurchasesCurrentFarm({ farm: result })
            ]),
            catchError((error) => of(new parseAction.GetWalkingFarmFail(error)))
          )
      )
    )
  );

  getSavedFarmReportsFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetSavedFarmReports>(parseAction.ParseActionTypes.GetSavedFarmReports)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey)
      ),
      filter(([_action, user]) => !isNil(user?.email)),
      switchMap(([_action, user, parseUrlType, appName]) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: {
                appName,
                email: user.email,
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/History`
          })
          .pipe(
            switchMap((result) => [new parseAction.GetSavedFarmReportsSuccess(result)]),
            catchError((error) => of(new parseAction.GetSavedFarmReportsFail(error)))
          )
      )
    )
  );

  getFarmReport$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetFarmReport>(parseAction.ParseActionTypes.GetFarmReport)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getAppConfigCreateFarmData),
        this.store.select(fromAppSettings.getFarmAlertSettingsAutoSubscribe),
        this.store.select(fromWalkingFarm.getWalkingFarmTurnOver),
        this.store.select(fromWalkingFarm.getWalkingFarmRawCoordinates)
      ),
      filter(([_action, user]) => !isNil(user?.email)),
      switchMap(([action, user, configData, farmAlerts, turnOver, rawFarmCoordinates]) =>
        this.oldApiProvider
          .getData(
            'GetFarmReportV2',
            {
              deviceData: this.pfHelperService.getComputeDeviceData(configData.deviceData),
              filterJson: JSON.stringify(action.payload.filterJson),
              key: configData.farmKey,
              shapeJson: JSON.stringify(action.payload.shapeJson)
            },
            'FARM-KEY-REFRESH'
          )
          .pipe(
            switchMap((response) => [
              new parseAction.CreateFarm({
                requestBody: {
                  appName: configData.appName,
                  email: user.email,
                  filters: action.payload.filterJson,
                  hasFarmAlerts: isBoolean(farmAlerts) ? farmAlerts : !!configData.hasFarmAlerts,
                  isPurchased: false,
                  name: action.payload.name.replace(/\//g, '-'),
                  properties: response['d'].Properties || [],
                  reportCaveats: response['d'].Message || [],
                  reportVersion: 1,
                  shapeCoordinates: rawFarmCoordinates,
                  turnoverRate: turnOver.turnOverPercent,
                  webServiceId: response['d'].ReportId
                }
              })
            ])
          )
      )
    )
  );

  createFarm$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.CreateFarm>(parseAction.ParseActionTypes.CreateFarm)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      switchMap(([action, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: action.payload.requestBody,
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}`
          })
          .pipe(
            switchMap((response) => {
              const data = action.payload.requestBody;

              return [
                new parseAction.CreateFarmSuccess({
                  changes: {
                    appName: data.appName,
                    email: data.email,
                    filters: {
                      ...data.filters,
                      MinBathrooms: data.filters.MinBathrooms,
                      MinBedrooms: data.filters.MinBedrooms
                    },
                    hasFarmAlerts: data.hasFarmAlerts,
                    id: response.id,
                    isDeleted: false,
                    isPurchased: false,
                    name: data.name,
                    propertyCount: data.properties.length,
                    reportDate: new Date(Date.now()).toISOString(),
                    shapeCoordinates: data.shapeCoordinates,
                    turnoverRate: data.turnoverRate,
                    updateDate: new Date(Date.now()).toISOString(),
                    webServiceId: data.webServiceId
                  },
                  id: response.id
                }),
                new parseAction.GetWalkingFarm(response.id)
              ];
            }),
            catchError((error) =>
              of(
                new parseAction.CreateFarmFail({
                  ...error,
                  message: error.error?.description ?? error.message
                })
              )
            )
          )
      )
    )
  );

  deleteFarmReportFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.DeleteFarmReport>(parseAction.ParseActionTypes.DeleteFarmReport)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      map(([action, parseUrlType]) => [action.payload, parseUrlType]),
      switchMap(([farmReportId, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'DELETE',
            requestOptions: {
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${farmReportId}`
          })
          .pipe(
            map(() => new parseAction.DeleteFarmReportSuccess(farmReportId)),
            catchError((error) =>
              of(
                new parseAction.DeleteFarmReportFail({
                  error,
                  farmReportId
                })
              )
            )
          )
      )
    )
  );

  updateFarmReportPropertiesInParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.UpdateFarmReportProperties>(parseAction.ParseActionTypes.UpdateFarmReportProperties))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppConfig.getAppInfoParseUrlType),
          this.store.select(walkingFarmSelectors.getWalkingFarmData)
        ),
        map(([action, parseUrlType, savedFarm]) => ({
          farmId: action.payload.farmId,
          parseUrlType,
          properties: action.payload.properties,
          savedFarm
        })),
        switchMap(({ properties, parseUrlType, farmId, savedFarm }) =>
          this.apiService
            .sendRequest({
              method: 'PATCH',
              requestOptions: {
                body: {
                  properties
                },
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${farmId}/Properties`
            })
            .pipe(
              switchMap(() => [
                new walkingFarmActions.UpdateViewedFarmProperties({
                  isSelectiveUpdate: true,
                  properties
                }),
                new walkingFarmActions.UpdateSavedFarm({
                  changes: { properties: isArray(savedFarm.properties) ? savedFarm.properties : [] },
                  id: farmId,
                  savedFarm
                }),
                new walkingFarmActions.FilterViewedFarm({
                  farmId: savedFarm.id
                }),
                new parseAction.UpdateFarmReportPropertiesSuccess(),
                new walkingFarmAction.LoadFarm(savedFarm)
              ]),
              catchError((error) => of(new parseAction.UpdateFarmReportPropertiesFail(error)))
            )
        )
      )
  );

  getFarmAlertsForFarmFromParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.GetFarmAlertsForFarm>(parseAction.ParseActionTypes.GetFarmAlertsForFarm))
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
        map(([action, parseUrlType]) => [action.payload, parseUrlType]),
        switchMap(([farmReportId, parseUrlType]) =>
          this.apiService
            .sendRequest({
              method: 'GET',
              requestOptions: {
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${farmReportId}/Alerts`
            })
            .pipe(
              map(
                (result) =>
                  new parseAction.GetFarmAlertsForFarmSuccess(
                    result?.map((alert) => ({
                      ...alert,
                      isRead: alert.isRead || false
                    })) || []
                  )
              ),
              catchError((error) => of(new parseAction.GetFarmAlertsForFarmFail(error)))
            )
        )
      )
  );

  markFarmAlertAsReadInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.MarkFarmAlertAsRead>(parseAction.ParseActionTypes.MarkFarmAlertAsRead)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      map(([action, parseUrlType]) => [action.payload, parseUrlType]),
      switchMap(([farmAlertId, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'PATCH',
            requestOptions: {
              body: {
                ids: [farmAlertId]
              },
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/Alert/Read`
          })
          .pipe(
            map(() => new parseAction.MarkFarmAlertAsReadSuccess(farmAlertId)),
            catchError((error) => of(new parseAction.MarkFarmAlertAsReadFail(error)))
          )
      )
    )
  );

  markMultipleFarmAlertsAsReadInParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.MarkMultipleFarmAlertsAsRead>(parseAction.ParseActionTypes.MarkMultipleFarmAlertsAsRead))
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
        map(([action, parseUrlType]) => ({ farmAlertIds: action.payload, parseUrlType })),
        switchMap(({ farmAlertIds, parseUrlType }) =>
          this.apiService
            .sendRequest({
              method: 'PATCH',
              requestOptions: {
                body: {
                  ids: farmAlertIds
                },
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/Alert/Read`
            })
            .pipe(
              map(() => new parseAction.MarkMultipleFarmAlertsAsReadSuccess(farmAlertIds)),
              catchError((error) => of(new parseAction.MarkMultipleFarmAlertsAsReadFail(error)))
            )
        )
      )
  );

  toggleFarmAlertSubscriptionFromParse$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.ToggleFarmAlertSubscription>(parseAction.ParseActionTypes.ToggleFarmAlertSubscription))
      .pipe(
        withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
        map(([action, parseUrlType]) => ({
          data: action.payload,
          parseUrlType
        })),
        switchMap(({ data, parseUrlType }) =>
          this.apiService
            .sendRequest({
              method: 'PATCH',
              requestOptions: {
                body: {
                  hasFarmAlerts: data.hasFarmAlerts
                },
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.farms}/${data.farmId}`
            })
            .pipe(
              map((result) => new parseAction.ToggleFarmAlertSubscriptionSuccess(result)),
              catchError((error) => of(new parseAction.ToggleFarmAlertSubscriptionFail(error)))
            )
        )
      )
  );

  getUserFromParse$ = createEffect(
    () =>
      this.actions$.pipe(ofType<parseAction.GetUser>(parseAction.ParseActionTypes.GetUser)).pipe(
        withLatestFrom(
          this.store.select(fromAppSettings.getAppSettingsUserProfileData),
          this.store.select(fromAppConfig.getAppInfoParseUrlType)
        ),
        filter(([_action, user]) => !isNil(user?.id)),
        switchMap(([_action, user, parseUrlType]) =>
          this.apiService
            .sendRequest({
              method: 'GET',
              requestOptions: {
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${user.id}`
            })
            .pipe(
              map((result: MobileUserGetAttributes) => {
                this.secureStorageService.updateInternalStorage({
                  user: result
                });
              })
            )
        )
      ),
    {
      dispatch: false
    }
  );

  getRegisteredUserFromParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.GetRegisteredUser>(parseAction.ParseActionTypes.GetRegisteredUser)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey)
      ),
      switchMap(([action, user, parseUrlType, appName]) =>
        this.apiService
          .sendRequest({
            method: 'GET',
            requestOptions: {
              params: user?.id
                ? {
                    parseType: parseUrlType
                  }
                : {
                    appName,
                    email: action.payload.email,
                    parseType: parseUrlType
                  }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}${user.id ? `/${user.id}` : ''}`
          })
          .pipe(
            switchMap((result: MobileUserGetAttributes) => [
              new parseAction.GetRegisteredUserSuccess({
                ...result,
                id: result.id ?? user.id
              }),
              new parseAction.UpdateUserSuccess(result),
              new appConfigGeneralInfoAction.SaveDataInInternalStorage()
            ]),
            catchError((error) => {
              this.pfHelperService.showUserFailureMessage();
              return of(new parseAction.GetRegisteredUserFail(error.message));
            })
          )
      )
    )
  );

  getRegisteredUserSuccess$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.GetRegisteredUserSuccess>(parseAction.ParseActionTypes.GetRegisteredUserSuccess))
      .pipe(switchMap(() => [new appParseAction.UpdateDeviceId()]))
  );

  createUserInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.CreateUser>(parseAction.ParseActionTypes.CreateUser)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getGeneralInfoAuthData),
        this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey),
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.store.select(fromAppConfig.getEnableFarmAlertAutoSubscriptions)
      ),
      filter(([_action, user]) => !isNil(user)),
      switchMap(([_action, user, authData, appName, parseUrlType, autoSubscribeFarmAlerts]) => {
        const requestBody: MobileUserCreateAttributes = {
          appName,
          autoSubscribeFarmAlerts,
          company: user.company,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          newDeviceId: this.deviceService.getDeviceId(),
          phone: user.phone,
          providerUsername: authData.username,
          securityCode: authData.repCode,
          tosVersion: user.tosVersion
        };

        return this.apiService
          .sendRequest({
            method: 'POST',
            requestOptions: {
              body: requestBody,
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}`
          })
          .pipe(
            switchMap((result: MobileUserCreateResponseAttributes) => [
              new parseAction.CreateUserSuccess({ id: result.id }),
              new appConfigGeneralInfoAction.SaveDataInInternalStorage()
            ]),
            catchError((error) => of(new parseAction.CreateUserFail(error)))
          );
      })
    )
  );

  updateUserInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.UpdateUser>(parseAction.ParseActionTypes.UpdateUser)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getAppInfoParseUrlType)
      ),
      filter(([_action, user]) => !isNil(user?.id)),
      switchMap(([action, user, parseUrlType]) => {
        const requestBody: MobileUserUpdateAttributes = {
          company: action.payload.company,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          phone: action.payload.phone
        };

        return this.apiService
          .sendRequest({
            method: 'PATCH',
            requestOptions: {
              body: requestBody,
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${user.id}`
          })
          .pipe(
            switchMap(() => [
              new parseAction.UpdateUserSuccess({
                company: action.payload.company,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                phone: action.payload.phone
              }),
              new appConfigGeneralInfoAction.SaveDataInInternalStorage()
            ]),
            catchError((error) => of(new parseAction.UpdateUserFail(error)))
          );
      })
    )
  );

  updateFirebaseToken$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.UpdateFirebaseToken>(parseAction.ParseActionTypes.UpdateFirebaseToken)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getAppInfoParseUrlType),
        this.platformDetectService.getIsMobileSubscription()
      ),
      filter(([_action, user, _parseUrlType, isMobilePlatform]) => isMobilePlatform && !isNil(user?.id)),
      switchMap(([action, user, parseUrlType]) => {
        const requestBody: MobileUserUpdateAttributes = {
          newFirebaseToken: action.payload.firebaseToken
        };

        return this.apiService
          .sendRequest({
            method: 'PATCH',
            requestOptions: {
              body: requestBody,
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${user.id}`
          })
          .pipe(switchMap(() => [new parseAction.UpdateFirebaseTokenSuccess()]));
      })
    )
  );

  updateUserDeviceInfo$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.UpdateDeviceId>(parseAction.ParseActionTypes.UpdateDeviceId)).pipe(
      withLatestFrom(
        this.store.select(fromAppSettings.getAppSettingsUserProfileData),
        this.store.select(fromAppConfig.getAppInfoParseUrlType)
      ),
      filter(([_action, user]) => !isNil(user?.id)),
      switchMap(([_action, user, parseUrlType]) => {
        const requestBody: MobileUserUpdateAttributes = {
          newDeviceId: this.deviceService.getDeviceId()
        };

        return this.apiService
          .sendRequest({
            method: 'PATCH',
            requestOptions: {
              body: requestBody,
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${user.id}`
          })
          .pipe(switchMap(() => [new parseAction.UpdateDeviceIdSuccess()]));
      })
    )
  );

  updateUserTokenInParse$ = createEffect(() =>
    this.actions$.pipe(ofType<parseAction.UpdateUserToken>(parseAction.ParseActionTypes.UpdateUserToken)).pipe(
      withLatestFrom(this.store.select(fromAppConfig.getAppInfoParseUrlType)),
      map(([action, parseUrlType]) => [action.payload, parseUrlType]),
      switchMap(([userId, parseUrlType]) =>
        this.apiService
          .sendRequest({
            method: 'PATCH',
            requestOptions: {
              params: {
                parseType: parseUrlType
              }
            },
            url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${userId}/Activity`
          })
          .pipe(
            map(() => new parseAction.UpdateUserTokenSuccess()),
            catchError((error) => of(new parseAction.UpdateUserTokenFail(error)))
          )
      )
    )
  );

  getFarmAlertSettings$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.GetFarmAlertSettings>(parseAction.ParseActionTypes.GetFarmAlertSettings))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppSettings.getAppSettingsUserProfileData),
          this.store.select(fromAppConfig.getAppInfoParseUrlType),
          this.store.select(fromAppConfig.getAppInfoConsolidatedParseAppKey)
        ),
        filter(([_action, user]) => !isNil(user?.email)),
        switchMap(([_action, user, parseUrlType, appName]) =>
          this.apiService
            .sendRequest({
              method: 'GET',
              requestOptions: {
                params: {
                  appName,
                  email: user.email,
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}`
            })
            .pipe(
              map(
                (result: MobileUserGetAttributes) =>
                  new parseAction.GetFarmAlertSettingsSuccess({
                    autoSubscribeFarmAlerts: isBoolean(result.autoSubscribeFarmAlerts)
                      ? result.autoSubscribeFarmAlerts
                      : null,
                    farmAlertEmailNotifications: isBoolean(result.farmAlertEmailNotifications)
                      ? result.farmAlertEmailNotifications
                      : null,
                    farmAlertPushNotifications: isBoolean(result.farmAlertPushNotifications)
                      ? result.farmAlertPushNotifications
                      : null
                  })
              ),
              catchError((error) => {
                this.pfHelperService.showSystemError(error.message);
                return of(new parseAction.GetFarmAlertSettingsFail(error));
              })
            )
        )
      )
  );

  updateUserFarmAlertSettings$ = createEffect(() =>
    this.actions$
      .pipe(ofType<parseAction.UpdateUserFarmAlertSettings>(parseAction.ParseActionTypes.UpdateUserFarmAlertSettings))
      .pipe(
        withLatestFrom(
          this.store.select(fromAppSettings.getAppSettingsUserProfileData),
          this.store.select(fromAppConfig.getAppInfoParseUrlType)
        ),
        filter(([_action, user]) => !isNil(user?.id)),
        switchMap(([action, user, parseUrlType]) => {
          const requestBody: MobileUserUpdateAttributes = {
            autoSubscribeFarmAlerts: action.payload.autoSubscribeFarmAlerts,
            farmAlertEmailNotifications: action.payload.farmAlertEmailNotifications,
            farmAlertPushNotifications: action.payload.farmAlertPushNotifications
          };

          return this.apiService
            .sendRequest({
              method: 'PATCH',
              requestOptions: {
                body: requestBody,
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${user.id}`
            })
            .pipe(
              map(() => new parseAction.UpdateUserFarmAlertSettingsSuccess()),
              catchError((error) => {
                this.pfHelperService.showSystemError(error.message);
                return of(new parseAction.UpdateUserFarmAlertSettingsFail(error));
              })
            );
        })
      )
  );

  deleteUserData$ = createEffect(
    () =>
      this.actions$.pipe(ofType<parseAction.DeleteUserData>(parseAction.ParseActionTypes.DeleteUserData)).pipe(
        withLatestFrom(
          this.store.select(fromAppSettings.getAppSettingsUserProfileData),
          this.store.select(fromAppConfig.getAppInfoParseUrlType)
        ),
        filter(([_action, user]) => !isNil(user?.id)),
        switchMap(([_action, user, parseUrlType]) =>
          this.apiService
            .sendRequest({
              method: 'DELETE',
              requestOptions: {
                params: {
                  parseType: parseUrlType
                }
              },
              url: `${ADMIN_PORTAL_CONFIG.apiUrl}/v1/${adminPortalEndpoints.mobileUsers}/${user.id}`
            })
            .pipe(
              map(async () => {
                const alert = await this.alertController.create({
                  backdropDismiss: false,
                  buttons: [
                    {
                      role: 'cancel',
                      text: 'Ok'
                    }
                  ],
                  message:
                    'Your data is now deleted.  Be sure to log out from any other installations of the app you may be using.'
                });

                await alert.present();

                alert.onWillDismiss().then(() => {
                  this.authService.logout();
                });
              }),
              catchError((error) => {
                this.pfHelperService.showSystemError(error.message);
                return of(new parseAction.DeleteUserDataFail());
              })
            )
        )
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private alertController: AlertController,
    private deviceService: DeviceService,
    private oldApiProvider: ApiProvider,
    private pfHelperService: PfHelperService,
    private secureStorageService: SecureStorageService,
    private mapUtils: MapUtils,
    private apiService: ApiService,
    private authService: AuthService,
    private platformDetectService: PlatformDetectService
  ) {}
}
