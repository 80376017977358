import { Injectable } from '@angular/core';

import { filter } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { ContainerService } from '../container/container.service';
import { MonetizationService } from '../monetization/monetization.service';
import { MorePageService } from '../more-page/more-page.service';
import { PlatformDetectService } from '../platform-detect/platform-detect.service';
import { SearchService } from '../search/search.service';
import { SecureStorageService } from '../secure-storage/secure-storage.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class SetupService {
  private triggerSetup = true;

  constructor(
    private configService: ConfigService,
    private containerService: ContainerService,
    private monetizationService: MonetizationService,
    private morePageService: MorePageService,
    private platformDetectService: PlatformDetectService,
    private secureStorageService: SecureStorageService,
    private searchService: SearchService,
    private appService: AppService
  ) {}

  initFactory() {
    return new Promise<void>((resolve) => {
      this.setupApp(resolve);
    });
  }

  private setupApp(callback?: (value: void | PromiseLike<void>) => void) {
    if (this.triggerSetup) {
      this.triggerSetup = false;

      this.secureStorageService.init();

      this.configService
        .init()
        .pipe(filter((isConfigLoaded) => isConfigLoaded))
        .subscribe(() => {
          this.containerService.init();
          this.platformDetectService.init();
          this.morePageService.init();
          this.monetizationService.init();
          this.searchService.init();

          this.appService.init();

          if (callback) {
            callback();
          }
        });
    }
  }
}
