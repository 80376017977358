import { Injectable } from '@angular/core';

import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { from, Observable, of } from 'rxjs';

import { appBuildNumber } from '../../../../environments/environment';
import { DeviceData } from '../../../typings/device';

import { PlatformDetectService } from '../platform-detect/platform-detect.service';

@Injectable()
export class DeviceService {
  private isMobilePlatform = true;

  constructor(
    private device: Device,
    private appVersion: AppVersion,
    private platformDetectService: PlatformDetectService
  ) {
    this.platformDetectService.getIsMobileSubscription().subscribe((isMobilePlatform) => {
      this.isMobilePlatform = isMobilePlatform;
    });
  }

  getInfo() {
    let observable: Observable<DeviceData> = of(null);

    if (this.isMobilePlatform) {
      observable = from(
        this.appVersion.getVersionNumber().then((version: string) => {
          return {
            appVersion: `${version}(${appBuildNumber})`,
            deviceDimensions: this.device.model,
            deviceType: this.device.model,
            os: this.device.platform.toLocaleUpperCase(),
            osVersion: this.device.version
          };
        })
      );
    }

    return observable;
  }

  getDeviceId() {
    let deviceUuid = Math.random().toString(36).substring(7);

    if (this.isMobilePlatform) {
      deviceUuid = this.device.uuid;
    }

    return deviceUuid;
  }
}
