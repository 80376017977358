import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { cloneDeep, filter as lodashFilter, find, forEach, has, map as lodashMap, values } from 'lodash-es';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { VirtualCurrencyPreviewData } from '../../../../typings/monetization';
import { SearchInputData } from '../../../../typings/search';
import { FormattedPropertyData } from '../../../../typings/walking-farm';

import { GenericComponent } from '../../generic/generic.component';

import * as fromMonetizationActions from '../../../../store/actions/monetization.actions';
import * as fromMonetizationSelectors from '../../../../store/selectors/monetization.selectors';

import { LayoutService } from '../../../services/layout/layout.service';

@Component({
  selector: 'smd-phone-and-email-modal',
  templateUrl: './phone-and-email-modal.component.html'
})
export class PhoneAndEmailModalComponent extends GenericComponent implements OnInit {
  private farmProperties: FormattedPropertyData[];
  private shouldUpdateDataModel = true;

  isMobileView = true;

  isModalOpen = false;
  phoneAndEmailPropertyList: { address: string; id: string; purchasedContactDetails: boolean }[];
  phoneAndEmailPropertiesModel: { [key: string]: boolean } = {};
  isPhoneAndEmailToggleVisible = true;
  isPhoneAndEmailPropertiesToggleActive = true;
  isConfirmButtonDisabled = true;

  @Input() set properties(data: FormattedPropertyData[]) {
    this.ngZone.run(() => {
      forEach(data, (property) => {
        this.phoneAndEmailPropertiesModel[property.id] = this.isPhoneAndEmailPropertiesToggleActive;
      });

      this.farmProperties = data;
    });
  }

  @Input() set isOpen(check: boolean) {
    this.ngZone.run(() => {
      if (check !== this.isModalOpen) {
        this.toggleModal(check);
      }
    });
  }

  @Output() modalToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private ngZone: NgZone,
    private store: Store,
    private alertController: AlertController,
    private navController: NavController,
    private layoutService: LayoutService
  ) {
    super();
  }

  ngOnInit() {
    this.addUniqueSubscription(
      'isMobileViewSubscription',
      this.layoutService.getMobileViewFlag().subscribe((isMobileView) => {
        this.ngZone.run(() => {
          this.isMobileView = isMobileView;
        });
      })
    );

    this.addUniqueSubscription(
      'inAppPurchasesPhoneAndEmailPreviewSubscription',
      this.store
        .select(fromMonetizationSelectors.selectInAppPurchasesPhoneAndEmailPreview)
        .pipe(filter((phoneAndEmailData) => phoneAndEmailData.isLoaded))
        .subscribe(async (phoneAndEmailData) => {
          await this.handlePhoneAndEmailPreviewAlert(phoneAndEmailData);
        })
    );

    this.addUniqueSubscription(
      'inAppPurchasesPhoneAndEmailPurchaseSubscription',
      this.store
        .select(fromMonetizationSelectors.selectInAppPurchasesPhoneAndEmailIsPurchaseCompleted)
        .pipe(
          filter((isPurchaseCompleted) => isPurchaseCompleted),
          withLatestFrom(
            this.store.select(fromMonetizationSelectors.selectInAppPurchasesPhoneAndEmailIsPurchaseSuccess)
          ),
          map((data) => ({
            isPurchaseSuccess: data[1]
          }))
        )
        .subscribe(async ({ isPurchaseSuccess }) => {
          await this.handlePhoneAndEmailPurchaseCompletedAlert(isPurchaseSuccess);
        })
    );
  }

  handlePhoneAndEmailPropertyList(searchKey?: string) {
    return lodashMap(
      lodashFilter(
        this.farmProperties,
        (property) => !searchKey || property.address?.toLowerCase().includes(searchKey?.toLowerCase())
      ),
      (property) => ({
        address: property.address,
        id: property.id,
        purchasedContactDetails: property.purchasedContactDetails
      })
    );
  }

  onPropertyCheckboxClick(propertyIds: string) {
    this.ngZone.run(() => {
      this.updatePhoneAndEmailModel([propertyIds]);

      const hasUncheckedItem: boolean = values(this.phoneAndEmailPropertiesModel).includes(false);

      if (hasUncheckedItem && this.isPhoneAndEmailPropertiesToggleActive === hasUncheckedItem) {
        this.shouldUpdateDataModel = false;
      }

      this.isPhoneAndEmailPropertiesToggleActive = !hasUncheckedItem;
    });
  }

  onSelectPhoneAndEmailPropertiesCancel() {
    this.ngZone.run(() => {
      this.isModalOpen = false;
    });
  }

  onSelectPhoneAndEmailPropertiesConfirm() {
    this.ngZone.run(() => {
      this.isModalOpen = false;
      this.isPhoneAndEmailToggleVisible = true;
    });

    const propertyIds = [];

    forEach(Object.keys(this.phoneAndEmailPropertiesModel), (propertyId) => {
      if (this.phoneAndEmailPropertiesModel[propertyId]) {
        propertyIds.push(propertyId);
      }
    });

    if (propertyIds.length) {
      this.store.dispatch(
        fromMonetizationActions.loadVirtualCurrencyPreview({
          propertyIds
        })
      );
    }
  }

  onPhoneAndEmailModalDismiss() {
    this.ngZone.run(() => {
      if (this.isModalOpen) {
        this.isModalOpen = false;
        this.modalToggle.emit(false);
      }
    });
  }

  onPhoneAndEmailPropertySearchInput(event: SearchInputData) {
    this.ngZone.run(() => {
      this.phoneAndEmailPropertyList = this.handlePhoneAndEmailPropertyList(event?.key);

      if (this.isPhoneAndEmailToggleVisible) {
        this.isPhoneAndEmailToggleVisible = false;
      }

      if (this.isPhoneAndEmailPropertiesToggleActive) {
        this.isPhoneAndEmailPropertiesToggleActive = false;
        this.onPhoneAndEmailPropertiesSelectAllToggleChange({ detail: { checked: false } });
      }
    });
  }

  onPhoneAndEmailPropertySearchInputClear(check: boolean) {
    this.ngZone.run(() => {
      if (check) {
        this.phoneAndEmailPropertyList = this.handlePhoneAndEmailPropertyList();
        this.isPhoneAndEmailToggleVisible = true;
      }
    });
  }

  onPhoneAndEmailPropertiesSelectAllToggleChange(event) {
    if (!this.shouldUpdateDataModel) {
      this.shouldUpdateDataModel = true;
    } else {
      this.ngZone.run(() => {
        const propertyIds: string[] = [];
        lodashMap(this.farmProperties, (property) => {
          propertyIds.push(property.id);
        });
        this.updatePhoneAndEmailModel(propertyIds, { isChecked: event.detail.checked });

        this.phoneAndEmailPropertyList = this.handlePhoneAndEmailPropertyList();
      });
    }
  }

  private updatePhoneAndEmailModel(propertyIds: string[], options?: { isChecked?: boolean }) {
    const modelClone = cloneDeep(this.phoneAndEmailPropertiesModel);

    forEach(propertyIds, (id) => {
      if (has(options, 'isChecked')) {
        modelClone[id] = options.isChecked;
      } else {
        modelClone[id] = !modelClone[id];
      }
    });

    this.ngZone.run(() => {
      this.phoneAndEmailPropertiesModel = {
        ...modelClone
      };

      this.updateIsConfirmButtonDisabledFlag();
    });
  }

  private toggleModal(check: boolean) {
    if (check) {
      this.phoneAndEmailPropertyList = this.handlePhoneAndEmailPropertyList();
    }

    this.updateIsConfirmButtonDisabledFlag();

    this.isModalOpen = check;
  }

  private updateIsConfirmButtonDisabledFlag() {
    this.ngZone.run(() => {
      this.isConfirmButtonDisabled = !find(this.phoneAndEmailPropertiesModel, (check) => check);
    });
  }

  private async handlePhoneAndEmailPreviewAlert(phoneAndEmailData: VirtualCurrencyPreviewData) {
    const check = phoneAndEmailData.badgerBucks.cost < phoneAndEmailData.badgerBucks.available;

    const alert = await this.alertController.create({
      buttons: check
        ? [
            {
              role: 'cancel',
              text: 'Cancel'
            },
            {
              handler: () => {
                const propertyIds = [];

                forEach(Object.keys(this.phoneAndEmailPropertiesModel), (propertyId) => {
                  if (this.phoneAndEmailPropertiesModel[propertyId]) {
                    propertyIds.push(propertyId);
                  }
                });

                if (propertyIds.length) {
                  this.store.dispatch(
                    fromMonetizationActions.loadVirtualCurrencyPurchase({
                      propertyIds
                    })
                  );
                }
              },
              text: 'Continue'
            }
          ]
        : [
            {
              handler: () => {
                this.toggleModal(true);
              },
              text: 'Change selection'
            },
            {
              handler: () => {
                this.navController.navigateRoot(['/', 'payment', 'store']);
              },
              text: 'Purchase more'
            }
          ],
      header: 'Get phones and e-mails',
      message: check
        ? `We estimate that getting phones/e-mails for the selected properties could cost up to ${phoneAndEmailData.badgerBucks.cost} Badger Bucks. Do you want to proceed?`
        : `We estimate that getting phones/e-mails for the selected properties could cost up to ${phoneAndEmailData.badgerBucks.cost} Badger Bucks, but you only have ${phoneAndEmailData.badgerBucks.available}. You can purchase more Badger Bucks or select fewer properties for the search.`
    });

    await alert.present();
  }

  private async handlePhoneAndEmailPurchaseCompletedAlert(check: boolean) {
    const alert = await this.alertController.create({
      buttons: [
        {
          role: 'cancel',
          text: 'Cancel'
        },
        {
          role: 'cancel',
          text: 'Ok'
        }
      ],
      header: 'Notification',
      message: check
        ? 'Thank you for purchasing. It might take some time to fetch and update the farm with the new information. You will receive a email when the process is complete.'
        : 'There was an issue handling your request. Please try again later.'
    });

    await alert.present();
  }
}
